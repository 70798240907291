import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';

const LabeledInput = ({ label, name, value, placeholder, type, size, ...rest }) => {
  return (
    <InputBox size={size}>
      <Label size={size}>{label}</Label>
      <Input
        name={name}
        value={value}
        size={size}
        placeholder={placeholder}
        type={type}
        {...rest}
      />
    </InputBox>
  );
};

const InputBox = styled.div`
  & + & {
    margin: 50px 0 0 0;
  }
  position: relative;

  ${(props) =>
    props.size === 'small' &&
    css`
      & + & {
        margin: 40px 0 0 0;
        position: relative;
      }
    `}
`;

const Label = styled.p`
  line-height: 16px;
  font-size: 16px;
  color: white;
  font-family: 'NanumSquareRoundEB';
  color: #2d2d2d;
  margin: 0 0 17px 7px;

  ${(props) =>
    props.size === 'small' &&
    css`
      font-size: 25px;
    `}
`;

const Input = styled.input`
  border: 1px solid ${theme.colors.gray};
  width: 100%;
  height: 56px;
  border-radius: 28px;
  font-size: 16px;
  font-family: 'NanumSquareRoundR';
  padding: 0 26px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${theme.colors.gray};
  }

  &:disabled {
    background-color: #f4f4f4;
    color: #bcbcbc;
  }

  ${(props) =>
    props.size === 'small' &&
    css`
      width: 400px;
      height: 60px;
      font-size: 20px;
    `}
`;

export default LabeledInput;

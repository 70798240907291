import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import menuImg from 'resources/img/icn_menu.png';
import closeImg from 'resources/img/btn_exit_black.png';
import logo from 'resources/img/logo.png';
import logo2x from 'resources/img/logo@2x.png';
import { Link, useNavigate } from 'react-router-dom';
import stores from 'stores';

const Navbar = ({ active }) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const { authStore } = stores;
  const navigate = useNavigate();
  const logout = () => {
    if (window.confirm('로그아웃 하시겠습니까?')) {
      authStore.postUserLogout();
    }
  };

  const navigateToChange = () => {
    navigate('/signSitter', {
      state: {
        modify: true,
      },
    });
  };

  const handleToggleMenu = () => {
    setMenuOpened(!menuOpened);
  };

  const navigatePage = (url) => {
    navigate(url);
    setMenuOpened(false);
  };

  return (
    <NavWrap>
      <FlexWrap>
        <Link to="/">
          <Logo>
            <LogoImg src={logo} srcSet={`${logo}, ${logo2x}`} alt="logo" />
          </Logo>
        </Link>
        <RightWrap>
          <MenuWrap>
            <MenuItem onClick={() => navigatePage('/introduce')}>
              <Menu active={active === 'introduce'}>회사소개</Menu>
            </MenuItem>
            <MenuItem onClick={() => navigatePage('/customerService')}>
              <Menu active={active === 'customerService'}>고객센터</Menu>
            </MenuItem>
            <MenuItem onClick={() => navigatePage('/sitterSearching')}>
              <Menu active={active === 'sitterSearching'}>시터 찾기</Menu>
            </MenuItem>
            <MenuItem onClick={() => navigatePage('/searchParent')}>
              <Menu active={active === 'searchParent'}>부모 찾기</Menu>
            </MenuItem>
          </MenuWrap>
          <LoginButtonWrap>
            {!localStorage.getItem('token') && (
              <>
                <Link to="/selectUserType">
                  <SignInButton>로그인</SignInButton>
                </Link>
              </>
            )}
            {!!localStorage.getItem('token') && (
              <>
                {localStorage.getItem('userType') === 'SITTER' && (
                  <SignUpButton onClick={navigateToChange}>정보변경</SignUpButton>
                )}
                <SignInButton onClick={logout}>로그아웃</SignInButton>
              </>
            )}
          </LoginButtonWrap>
        </RightWrap>
        <MenuImg onClick={handleToggleMenu} src={menuOpened ? closeImg : menuImg} />
      </FlexWrap>
      {menuOpened && (
        <MobileMenuContainer>
          <MobileMenu onClick={() => navigatePage('/introduce')}>회사소개</MobileMenu>
          <MobileMenu onClick={() => navigatePage('/customerService')}>고객센터</MobileMenu>
          <MobileMenu onClick={() => navigatePage('/sitterSearching')}>시터찾기</MobileMenu>
          <MobileMenu onClick={() => navigatePage('/searchParent')}>부모찾기</MobileMenu>
          <Link to="/selectUserType">
            <SignInButton>로그인</SignInButton>
          </Link>
        </MobileMenuContainer>
      )}
    </NavWrap>
  );
};

const NavWrap = styled.div`
  width: 100%;
  height: 106px;
  font-family: 'NanumSquareRoundEB';
  box-shadow: 0 2px 5px 0 rgba(183, 183, 183, 0.75);
  position: absolute;
  z-index: 1;
  background-color: white;

  @media ${theme.laptop} {
    height: 60px;
  }
`;

const FlexWrap = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1500px;
  min-width: 900px;

  @media ${theme.laptop} {
    max-width: 100%;
    min-width: 100%;
  }
`;

const Logo = styled.div`
  margin: 34px 0 0 130px;

  @media ${theme.laptop} {
    margin: 18px 0 0 30px;
  }
`;

const LogoImg = styled.img`
  object-fit: contain;

  @media ${theme.laptop} {
    height: 32px;
  }
`;

const RightWrap = styled.div`
  margin: 0 130px 0 0;
  display: flex;

  @media ${theme.laptop} {
    display: none;
  }
`;

const MenuWrap = styled.div`
  display: flex;
  margin: 62px 15px 0 0;
`;

const Menu = styled.button`
  background-color: white;
  padding: 0 15px;
  margin: 0;
  font-size: 14px;
  color: ${theme.colors.dark};
  font-family: 'NanumSquareRoundEB';
  height: 20px;
  line-height: 22px;
  ${(props) =>
    props.active &&
    css`
      color: ${theme.colors.primary};
    `}
`;

const LoginButtonWrap = styled.div`
  padding: 50px 0 0 0;
`;

const SignUpButton = styled.button`
  font-size: 13px;
  background-color: ${theme.colors.primary};
  color: white;
  font-family: 'NanumSquareRoundEB';
  padding: 12px 22px;
  border-radius: 24px;
`;

const SignInButton = styled(SignUpButton)`
  background-color: white;
  color: ${theme.colors.dark};
  border: 1px solid ${theme.colors.dark};
  margin: 0 0 0 11.8px;

  @media ${theme.laptop} {
    width: 100%;
    margin-top: 120px;
    margin-bottom: 20px;
  }
`;

const MenuImg = styled.img`
  display: none;
  cursor: pointer;

  @media ${theme.laptop} {
    display: inherit;
    width: 24px;
    height: 24px;
    margin-top: 18px;
    margin-right: 12px;
  }
`;

const MobileMenuContainer = styled.div`
  background: white;
  margin-top: 5px;
  padding-left: 30px;
  padding-right: 30px;
`;

const MobileMenu = styled.div`
  font-family: 'NanumSquareRoundEB';
  color: #383b40;
  font-size: 14px;
  height: 48px;
  line-height: 48px;
  cursor: pointer;
`;

const MenuItem = styled.div``;

export default Navbar;

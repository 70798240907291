import React from 'react';
import { useCountdown } from 'utils/useCountdown';

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div>
      {days}일 {hours} : {minutes} : {seconds} 남음
    </div>
  );
};

const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <div>마감</div>;
  } else {
    return <ShowCounter days={days} hours={hours} minutes={minutes} seconds={seconds} />;
  }
};
export default CountdownTimer;

import { postParentUsersCertificationApi, postParentUsersCertificationCodeApi, postParentSitterRequestsApi } from 'api/API';
import { makeAutoObservable, runInAction } from 'mobx';

class ParentStore {
  constructor() {
    makeAutoObservable(this);
  }

  postParentUsersCertification = async (data) => {
    await postParentUsersCertificationApi(data)
      .then((res) => {
        console.log(res);
        if ('SUCCESS' === res.data.responseCode) {
          localStorage.setItem('userType', res.data.data.userType);
          localStorage.setItem('token', res.data.data.accessToken);
          alert('요청에 성공했습니다.');
          window.location.href = '/';
          return;
        }
        alert('요청에 실패했습니다.')
      })
      .catch((error) => {
        console.log(error);
      });
  };

  postParentUsersCertificationCode = async (data) => {
    await postParentUsersCertificationCodeApi(data)
      .then((res) => {
        console.log(res);
        if ('SUCCESS' === res.data.responseCode) {
          alert('요청에 성공했습니다.')
          return;
        }
        alert('요청에 실패했습니다.')
      })
      .catch((error) => {
        console.log(error);
      });
  };

  postParentSitterRequestsApi = async (data) => {
    await postParentSitterRequestsApi(data)
    .then((res) => {
      console.log(res);
      if ('SUCCESS' === res.data.responseCode) {
        alert('요청에 성공했습니다.')
        window.location.href = '/';
        return;
      }
      alert('요청에 실패했습니다.')
    })
    .catch((error) => {
      console.log(error);
    });
  }
}

const parentStore = new ParentStore();

export default parentStore;

import React, { useState, useEffect, useSearchParams } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import bgMenuCommon from 'resources/img/bg_menu_common.png';
import bgMenuCommonMobile from 'resources/img/bg_menu_common_mobile.png';
import icnHome from 'resources/img/icn_home.png';
import icnArrowRight from 'resources/img/icn_arrow_right.png';
import DaumPostcode from 'react-daum-postcode';
import Checkbox from 'components/atoms/Checkbox';
import PopUp from 'components/atoms/PopUp';
import LabeledInput from 'components/atoms/LabeledInput';
import { observer } from 'mobx-react';
import stores from 'stores';
import { toJS } from 'mobx';

const SignSitterBasic = observer((props) => {
  const modify = props.modify;

  const { sitterStore, counrtyStore, commonStore } = stores;
  useEffect(() => {
    counrtyStore.getCountries();
    if (modify) {
      sitterStore.getSitterUsersMe();
    }
  }, [counrtyStore]);

  useEffect(() => {
    if (modify) {
      setInputs({
        name: sitterStore.mySitterInfo?.user?.name,
        birth: sitterStore.mySitterInfo.birthDate,
        contact: sitterStore.mySitterInfo.user?.cellphone,
        email: sitterStore.mySitterInfo.user?.email,
        address: sitterStore.mySitterInfo.address,
        addressCode: sitterStore.mySitterInfo.addressCode,
        sido: sitterStore.mySitterInfo.sido,
        sigungu: sitterStore.mySitterInfo.sigungu,
        sigunguCode: sitterStore.mySitterInfo.sigunguCode,
        detailAddress: sitterStore.mySitterInfo.addressDetails,
      });
      setCountryInfo(sitterStore?.mySitterInfo?.country);
    }
  }, [sitterStore.mySitterInfo]);

  const countryStoreData = toJS(counrtyStore.countryData);

  const [inputs, setInputs] = useState({
    name: '',
    nationality: '',
    password: '',
    passwordCheck: '',
    birth: '',
    contact: '',
    email: '',
    address: '',
    addressCode: '',
    sido: '',
    sigungu: '',
    sigunguCode: '',
    detailAddress: '',
    certificationCode: '',
  });

  const {
    name,
    nationality,
    password,
    passwordCheck,
    birth,
    contact,
    email,
    address,
    addressCode,
    sido,
    sigungu,
    sigunguCode,
    detailAddress,
    certificationCode,
  } = inputs;

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const onBirthChange = (e) => {
    const { value, name } = e.target;
    // console.log(value, name);
    let birthFormatted = '';
    let onlyNumber = value.replaceAll('-', '');
    if (onlyNumber.length > 6) {
      birthFormatted = `${onlyNumber.substring(0, 4)}-${onlyNumber.substring(
        4,
        6,
      )}-${onlyNumber.substring(6, 8)}`;
    } else if (onlyNumber.length > 4) {
      birthFormatted = `${onlyNumber.substring(0, 4)}-${onlyNumber.substring(4, 6)}`;
    } else {
      birthFormatted = onlyNumber.substring(0, 4);
    }
    setInputs({
      ...inputs,
      [name]: birthFormatted,
    });
  };

  const [showPostCode, setShowPostCode] = useState(false);

  const handleAddressComplete = (data) => {
    setInputs({
      ...inputs,
      address: data.address,
      addressCode: data.zonecode,
      sido: data.sido,
      sigungu: data.sigungu,
      sigunguCode: data.sigunguCode,
    });
    setShowPostCode(false);
  };

  const postCodeStyle = {
    display: 'block',
    position: 'relative',
    top: '17px',
    left: '0',
    width: '700px',
    height: '400px',
    zIndex: 100,
    border: '2px solid #bcbcbc',
    borderRadius: '10px',
    padding: '5px',
  };

  const [popupVisible, setPopupVisible] = useState(false);

  const handleOpenPopup = () => {
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  const [privacyCheck, setPrivacyCheck] = useState(false);

  const handleChangePrivacyCheck = () => {
    setPrivacyCheck(!privacyCheck);
  };

  const [showCertification, setShowCertification] = useState(false);

  const handleCertification = () => {
    if (!contact) {
      return;
    }
    commonStore.postCertificationCode({ cellphone: contact });
  };

  useEffect(() => {
    if (commonStore.cellphoneShowInput === true) {
      setShowCertification(true);
    }
  }, [commonStore.cellphoneShowInput]);

  const handleCheckCertificationCode = () => {
    commonStore.postCertification({ cellphone: contact, code: certificationCode });
  };

  const [checkCertification, setCheckCertification] = useState(false);

  useEffect(() => {
    if (commonStore.checkCertification === true) {
      setCheckCertification(true);
    }
  }, [commonStore.checkCertification]);

  const [countryInfo, setCountryInfo] = useState(sitterStore.mySitterInfo?.country);

  const handleCountryTab = (tab) => {
    setCountryInfo(tab);
  };

  const handleSubmit = (e) => {
    if (!modify) {
      if (
        !name ||
        !countryInfo ||
        !password ||
        !passwordCheck ||
        !birth ||
        !contact ||
        !email ||
        !address ||
        !addressCode ||
        !detailAddress ||
        !certificationCode
      ) {
        alert('필수 값을 입력해 주세요.');
        return;
      }
      if (!checkCertification) {
        alert('휴대폰 인증이 완료되지 않았습니다.');
        return;
      }
    } else {
      if (
        !name ||
        !countryInfo ||
        !birth ||
        !contact ||
        !email ||
        !address ||
        !addressCode ||
        !detailAddress
      ) {
        alert('필수 값을 입력해 주세요.');
        return;
      }
      if (sitterStore.mySitterInfo.user.cellphone !== contact && !checkCertification) {
        alert('휴대폰 인증이 완료되지 않았습니다.');
        return;
      }
    }
    if (password !== passwordCheck) {
      alert('비밀번호가 일치하지 않습니다.');
      return;
    }
    if (!privacyCheck) {
      alert('약관에 동의해 주세요.');
      return;
    }
    sitterStore.setName(name);
    sitterStore.setEmail(email);
    sitterStore.setPassword(password);
    sitterStore.setCellphone(contact);
    sitterStore.setCountry(countryInfo);
    sitterStore.setBirthDate(birth);
    sitterStore.setSido(sido);
    sitterStore.setSigungu(sigungu);
    sitterStore.setSigunguCode(sigunguCode);
    sitterStore.setAddressCode(addressCode);
    sitterStore.setAddress(address);
    sitterStore.setAddressDetails(detailAddress);
    sitterStore.setNationalityEtc(nationality);
    sitterStore.setTab('additional');
  };

  return (
    <>
      <Wrap>
        <CurrentMenu image={bgMenuCommon} mobileImage={bgMenuCommonMobile}>
          <IndicatorContainer>
            <IndicatorFirst src={icnHome} />
            <IndicatorArrow src={icnArrowRight} />
            <IndicatorCurrent>{modify ? '시터 정보 변경하기' : '시터 가입하기'}</IndicatorCurrent>
          </IndicatorContainer>
          {modify ? '시터 정보 변경하기' : '시터 가입하기'}
        </CurrentMenu>
        <ContentContainer>
          <Form>
            <LabeledInput
              label="이름"
              name="name"
              value={name}
              placeholder="이름을 입력해주세요"
              onChange={onChange}
              disabled={modify}
            />
            <LabeledInput
              label="이메일"
              name="email"
              value={email}
              placeholder="이메일을 입력해주세요"
              onChange={onChange}
              disabled={modify}
            />
            <LabeledInput
              label="비밀번호"
              name="password"
              value={password}
              type="password"
              placeholder="비밀번호를 입력해주세요"
              autoComplete="new-password"
              onChange={onChange}
            />
            <LabeledInput
              label="비밀번호 확인"
              name="passwordCheck"
              value={passwordCheck}
              type="password"
              placeholder="비밀번호를 한 번 더 입력해주세요"
              autoComplete="new-password"
              onChange={onChange}
            />
            <LabelMargin></LabelMargin>
            <LabelInput>
              <Label>연락처</Label>
              <ContactInputWrap>
                <Input
                  name="contact"
                  value={contact}
                  placeholder="연락처를 입력해주세요"
                  onChange={onChange}
                  disabled={checkCertification}
                />
                <ContactInputButton
                  type="button"
                  onClick={() => handleCertification()}
                  disabled={checkCertification}
                >
                  번호 인증
                </ContactInputButton>
              </ContactInputWrap>
              {showCertification && (
                <ContactInputWrap>
                  <Input
                    name="certificationCode"
                    value={certificationCode}
                    placeholder="인증번호를 입력해주세요"
                    onChange={onChange}
                    disabled={checkCertification}
                  />
                  <ContactInputButton
                    type="button"
                    onClick={() => handleCheckCertificationCode()}
                    disabled={checkCertification}
                  >
                    인증번호 확인
                  </ContactInputButton>
                </ContactInputWrap>
              )}
            </LabelInput>
            <LabelInput>
              <Label>국적</Label>
              <NationalButton onClick={handleOpenPopup} type="button">
                {countryInfo ? countryInfo.nameInOrigin : '국적을 입력해주세요'}
              </NationalButton>
              <Input
                name="nationality"
                value={nationality}
                placeholder="선택지에 없는 경우 직접 입력해주세요"
                onChange={onChange}
              />
            </LabelInput>
            <LabeledInput
              label="생년월일"
              name="birth"
              value={birth}
              placeholder="생년월일을 입력해주세요 (ex. 2000-01-01)"
              onChange={onBirthChange}
            />
            <LabelMargin></LabelMargin>
            <LabelInput>
              <Label>주소</Label>
              <ZipCode>
                <ZipCodeInput
                  name="zipCode"
                  placeholder="우편번호"
                  defaultValue={inputs.addressCode}
                  disabled
                />
                <ZipCodeButton type="button" onClick={() => setShowPostCode(!showPostCode)}>
                  우편번호 찾기
                </ZipCodeButton>
              </ZipCode>
              <AddressInput placeholder="주소" defaultValue={inputs.address} disabled />
              <AddressInput
                name="detailAddress"
                value={detailAddress}
                placeholder="상세주소를 입력해주세요"
                onChange={onChange}
              />
              {showPostCode ? (
                <div>
                  <DaumPostcode onComplete={handleAddressComplete} style={postCodeStyle} />
                </div>
              ) : (
                <></>
              )}
            </LabelInput>
            <Checkbox
              type="checkbox"
              name="privacyCheck"
              handleClick={handleChangePrivacyCheck}
              isChecked={privacyCheck}
            >
              개인정보 수집/이용 동의
            </Checkbox>
            <PrivacyNotice>
              * 수강생 출석 확인과 서비스 품질 개선 목적으로 ZOOM 강의 영상을 실시간 녹화하고
              있습니다.
              <br /> 해당 녹화본은 수집 목적외 용도로 사용되지 않으며, 보유기간이 지난 후 즉시
              폐기됩니다.
            </PrivacyNotice>
            <SubmitButton onClick={() => handleSubmit()} type="button">
              다음
            </SubmitButton>
          </Form>
        </ContentContainer>
      </Wrap>
      <PopUp visible={popupVisible} onClose={handleClosePopup}>
        <PopUpTitle>국적 선택</PopUpTitle>
        <PopUpTab>
          {countryStoreData.map((item) => (
            <PopUpLanguageItemWrap
              onClick={() => handleCountryTab(item)}
              tab={countryInfo?.countryIsoCode === item.countryIsoCode}
              key={item.countryIsoCode}
            >
              <PopUpLanguageItem>
                <Flag>
                  <img src={item.flagImgUrl} alt="flag" />
                </Flag>
                <FlagText>{item.nameInOrigin}</FlagText>
              </PopUpLanguageItem>
            </PopUpLanguageItemWrap>
          ))}
        </PopUpTab>
        <PopUpButton onClick={handleClosePopup}>선택완료</PopUpButton>
      </PopUp>
    </>
  );
});
const Wrap = styled.div`
  padding: 106px 0 0 0;

  @media ${theme.laptop} {
    padding: 60px 0 0 0;
  }

  @media ${theme.tablet} {
  }
`;

const CurrentMenu = styled.div`
  height: 320px;
  color: white;
  font-family: 'NPSfontEB';
  font-size: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  ${(props) =>
    css`
      background-image: url(${props.image});
    `}

  @media ${theme.desktop} {
    height: 240px;
    font-size: 36px;

    ${(props) =>
      css`
        background-image: url(${props.mobileImage});
      `}
  }
`;

const IndicatorContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 30px;
  display: flex;
`;

const IndicatorFirst = styled.img`
  height: 20px;
`;

const IndicatorArrow = styled.img``;

const IndicatorCurrent = styled.span`
  font-size: 13px;
  color: white;
  font-family: 'NanumSquareRoundEB';
  line-height: 20px;
`;

const ContentContainer = styled.div`
  width: 1280px;
  margin: 40px auto 0px;
  text-align: left;
  padding: 34px 40px 80px;
  display: block;

  @media ${theme.desktop} {
    width: inherit;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 25px;
    margin-bottom: 80px;
    border-radius: 10px;
    padding: 24px 10px;
  }

  @media ${theme.laptop} {
    display: block;
  }
`;

const Form = styled.form`
  width: 700px;
  margin: 20px auto 0;

  @media ${theme.tablet} {
    width: 100%;
  }
`;

const LabelMargin = styled.div`
  margin: 50px 0 0 0;
`;

const LabelInput = styled.div`
  margin: 0 0 40px 0;
  position: relative;
`;

const Label = styled.p`
  font-size: 16px;
  font-family: 'NanumSquareRoundEB';
  letter-spacing: -1.5px;
  color: #2d2d2d;
  margin: 0 0 17px 7px;
`;

const Input = styled.input`
  border: 1px solid ${theme.colors.gray};
  width: 100%;
  height: 56px;
  border-radius: 28px;
  font-size: 16px;
  font-family: 'NanumSquareRoundR';
  padding: 0 26px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${theme.colors.gray};
  }

  &:disabled {
    background-color: #f4f4f4;
    color: #bcbcbc;
  }
`;

const ContactInputWrap = styled.div`
  display: flex;

  & + & {
    margin-top: 10px;
  }
`;

const ZipCode = styled.div`
  display: flex;
`;

const ZipCodeInput = styled(Input)``;

const ZipCodeButton = styled.button`
  width: 160px;
  height: 56px;
  border: 1px solid #383b40;
  border-radius: 28px;
  font-size: 16px;
  font-family: 'NanumSquareRoundEB';
  color: #383b40;
  background-color: white;
  margin: 0 0 0 15px;

  @media ${theme.laptop} {
    font-size: 14px;
  }
`;

const ContactInputButton = styled(ZipCodeButton)`
  width: 160px;
  &:disabled {
    cursor: not-allowed;
    background-color: #f4f4f4;
  }
`;

const AddressInput = styled(Input)`
  margin: 17px 0 0 0;
`;

const PrivacyNotice = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-family: 'NanumSquareRoundR';
  color: #747983;
`;

const SubmitButton = styled.button`
  width: 100%;
  height: 64px;
  border-radius: 32px;
  margin: 55px auto 33px;
  font-size: 18px;
  background: ${theme.colors.primary};
  color: white;
  font-family: 'NanumSquareRoundEB';
`;

const NationalButton = styled.button`
  border: 1px solid ${theme.colors.gray};
  width: 100%;
  height: 56px;
  border-radius: 28px;
  font-size: 16px;
  font-family: 'NanumSquareRoundR';
  padding: 0 26px;
  color: ${theme.colors.gray};
  margin: 0 0 15px 0;
  background-color: transparent;
  text-align: left;
`;

const PopUpTitle = styled.p`
  text-align: center;
  font-family: 'GmarketSansMedium';
  font-size: 25px;
`;

const PopUpTab = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 70px 0 0 0;
`;

const PopUpLanguageItemWrap = styled.div`
  width: 92px;
  height: 108px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.tab === true &&
    css`
      background-color: rgba(7, 0, 133, 0.2);
      border-radius: 5px;
    `}
`;

const PopUpLanguageItem = styled.div`
  text-align: center;
`;

const Flag = styled.div`
  width: 30px;
  height: 20px;
  background-color: #eee;
  margin: 0 auto 25px;

  & > img {
    width: 30px;
    height: 20px;
    object-fit: cover;
  }
`;

const FlagText = styled.p`
  font-size: 12px;
  font-family: 'GmarketSansMedium';
`;

const PopUpButton = styled.button`
  width: 150px;
  border-radius: 30px;
  height: 60px;
  border: 2px solid ${theme.colors.primary};
  margin: 20px auto;
  color: ${theme.colors.primary};
  background-color: white;
  font-size: 20px;
  display: block;
  font-family: 'GmarketSansMedium';
`;

export default SignSitterBasic;

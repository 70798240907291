import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import Navbar from 'components/templates/Navbar';
import Footer from 'components/templates/Footer';
import Checkbox from 'components/atoms/Checkbox';

const ResetSitterInfoAdd = () => {
  const [inputs, setInputs] = useState({
    hopeSalary: '',
    introduce: '',
  });

  const { hopeSalary, introduce } = inputs;

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const childsAge = [
    { value: 'NEWBORN', text: '신생아', subText: '(출생 후 1주일 ~ 1개월 된 아기)' },
    { value: 'BABY_BEFORE_WALK', text: '영아', subText: '(걷기 전 단계의 아기)' },
    { value: 'BABY_BEFORE_6', text: '유아', subText: '(만 6세까지의 아이)' },
    { value: 'SCHOOL_CHILD', text: '초등학생', subText: '(7세부터 13세까지의 아이)' },
  ];

  const [selectedChildsAge, setSelectedChildsAge] = useState([]);

  const handleChildsAge = (e) => {
    const { value, checked } = e.target;
    setSelectedChildsAge([...selectedChildsAge, value]);
    if (!checked) {
      setSelectedChildsAge(selectedChildsAge.filter((item) => item !== value));
    }
  };

  const [residentTutor, setResidentTutor] = useState(true);

  const handleResident = (e) => {
    setResidentTutor(!residentTutor);
  };

  const [driving, setDriving] = useState(true);

  const handleDriving = (e) => {
    setDriving(!driving);
  };

  const [trainingStatus, setTrainingStatus] = useState(true);

  const handleTrainingStatus = (e) => {
    setTrainingStatus(!trainingStatus);
  };

  const [resume, setResume] = useState(null);
  const resumeRef = useRef();

  const handleChangeFile = (e) => {
    const uploadFile = e.target.files[0];
    if (uploadFile) {
      setResume(uploadFile);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Navbar />
      <Wrap>
        <Title>추가 입력 사항 변경</Title>
        <Form onSubmit={handleSubmit}>
          <LabelInput>
            <Label>돌봄 가능 나이대 (중복 선택 가능)</Label>
            <CheckboxWrap>
              {childsAge.map((item) => (
                <Checkbox
                  key={item.value}
                  defaultValue={item.value}
                  type="checkbox"
                  name="childsAgeGroup"
                  handleClick={(e) => handleChildsAge(e)}
                  isChecked={selectedChildsAge.includes(item.value)}
                >
                  {item.text}
                  <CheckboxSubText>{item.subText}</CheckboxSubText>
                </Checkbox>
              ))}
            </CheckboxWrap>
          </LabelInput>
          <LabelInput>
            <Label>입주 가능 여부</Label>
            <CheckboxWrapFlex>
              <CheckboxFlexBox>
                <Checkbox
                  key="입주가능여부"
                  defaultValue={true}
                  type="radio"
                  name="residentGroup"
                  handleClick={handleResident}
                  isChecked={residentTutor === true}
                >
                  가능
                </Checkbox>
              </CheckboxFlexBox>
              <CheckboxFlexBox>
                <Checkbox
                  key="입주가능여부"
                  defaultValue={false}
                  type="radio"
                  name="residentGroup"
                  handleClick={handleResident}
                  isChecked={residentTutor === false}
                >
                  불가능
                </Checkbox>
              </CheckboxFlexBox>
            </CheckboxWrapFlex>
          </LabelInput>
          <LabelInput>
            <Label>희망 시급 입력</Label>
            <SmallInput
              name="hopeSalary"
              value={hopeSalary}
              placeholder="최저임금은 9,160원입니다."
              onChange={onChange}
            />
            <MoneyIcon>₩</MoneyIcon>
          </LabelInput>
          <LabelInput>
            <Label>운전 가능 여부</Label>
            <CheckboxWrapFlex>
              <CheckboxFlexBox>
                <Checkbox
                  key="운전가능"
                  defaultValue={true}
                  type="radio"
                  name="drivingGroup"
                  handleClick={handleDriving}
                  isChecked={driving === true}
                >
                  가능
                </Checkbox>
              </CheckboxFlexBox>
              <CheckboxFlexBox>
                <Checkbox
                  key="운전불가능"
                  defaultValue={false}
                  type="radio"
                  name="drivingGroup"
                  handleClick={handleDriving}
                  isChecked={driving === false}
                >
                  불가능
                </Checkbox>
              </CheckboxFlexBox>
            </CheckboxWrapFlex>
          </LabelInput>
          <LabelInput>
            <Label>교육 이수 여부</Label>
            <CheckboxWrap>
              <Checkbox
                key="교육이수"
                defaultValue={true}
                type="radio"
                name="trainingStatusGroup"
                handleClick={handleTrainingStatus}
                isChecked={trainingStatus === true}
              >
                글로벌 시터 교육을 이수함
              </Checkbox>
              <Checkbox
                key="교육불이수"
                defaultValue={false}
                type="radio"
                name="trainingStatusGroup"
                handleClick={handleTrainingStatus}
                isChecked={trainingStatus === false}
              >
                글로벌 시터 교육을 이수하지 않음
              </Checkbox>
            </CheckboxWrap>
          </LabelInput>
          <LabelInput>
            <Label>이력서 업로드</Label>
            <FlexInputWrap>
              <FileBox>
                <FileText>{resume ? resume.name : '파일을 업로드 해주세요.'}</FileText>
              </FileBox>
              <FlexInputButton
                type="button"
                onClick={() => {
                  resumeRef.current.click();
                }}
              >
                파일 올리기
              </FlexInputButton>
              <InputDisplay type="file" onChange={handleChangeFile} ref={resumeRef} />
            </FlexInputWrap>
          </LabelInput>
          <LabelInput>
            <Label>자기 소개</Label>
            <Textarea
              name="introduce"
              value={introduce}
              placeholder="자기소개를 입력해주세요."
              onChange={onChange}
            />
          </LabelInput>
          <SubmitButton type="submit">시터 등록 완료</SubmitButton>
        </Form>
      </Wrap>
      <Footer />
    </>
  );
};
const Wrap = styled.div`
  padding: 106px 0 0 0;

  @media ${theme.laptop} {
    padding: 60px 0 0 0;
  }
`;
const Title = styled.p`
  color: ${theme.colors.deepGreen};
  font-family: 'GmarketSansMedium';
  font-size: 50px;
  letter-spacing: -1.25px;
  text-align: center;
  margin: 72px 0 53px 0;
`;

const Form = styled.form`
  width: 700px;
  margin: 80px auto 0;
`;

const LabelInput = styled.div`
  margin: 0 0 100px 0;
  position: relative;
`;

const Label = styled.p`
  font-size: 30px;
  line-height: 30px;
  font-family: 'GmarketSansMedium';
  letter-spacing: -1.5px;
  color: #2d2d2d;
  margin: 0 0 17px 7px;
`;

const Input = styled.input`
  border: 2px solid ${theme.colors.gray};
  width: 100%;
  height: 83px;
  border-radius: 10px;
  font-size: 25px;
  font-family: 'GmarketSansMedium';
  padding: 0 26px;

  &::placeholder {
    color: ${theme.colors.gray};
  }

  &:disabled {
    background-color: #f4f4f4;
    color: #bcbcbc;
  }
`;

const FlexInputWrap = styled.div`
  display: flex;
  justify-content: space-between;

  & + & {
    margin-top: 10px;
  }
`;

const FlexInputButton = styled.button`
  height: 83px;
  border: 2px solid ${theme.colors.gray};
  border-radius: 10px;
  font-size: 21px;
  font-family: 'GmarketSansMedium';
  color: #848484;
  background-color: white;
  width: 190px;
  margin: 0 0 0 15px;
`;

const SubmitButton = styled.button`
  border: 4px solid ${theme.colors.primary};
  width: 300px;
  height: 84px;
  border-radius: 50px;
  background-color: white;
  margin: 115px auto 93px;
  font-size: 30px;
  color: ${theme.colors.primary};
  letter-spacing: -0.75px;
  font-family: 'GmarketSansMedium';
  display: block;
`;

const CheckboxWrap = styled.div`
  margin: 40px 0 0 7px;
`;

const CheckboxWrapFlex = styled(CheckboxWrap)`
  display: flex;
`;

const CheckboxSubText = styled.span`
  font-size: 20px;
  font-family: 'GmarketSansLight';
  margin: 0 0 0 15px;
`;

const CheckboxFlexBox = styled.div`
  width: 300px;
`;

const SmallInput = styled(Input)`
  width: 490px;
`;

const MoneyIcon = styled.span`
  font-size: 30px;
  font-family: 'GmarketSansMedium';
  margin-left: 15px;
`;

const Textarea = styled.textarea`
  border: 2px solid ${theme.colors.gray};
  width: 100%;
  height: 300px;
  border-radius: 10px;
  font-size: 25px;
  font-family: 'GmarketSansMedium';
  padding: 20px 26px;
  resize: none;
  box-sizing: border-box;

  &::placeholder {
    color: ${theme.colors.gray};
  }

  &:disabled {
    background-color: #f4f4f4;
    color: #bcbcbc;
  }
`;

const FileBox = styled.div`
  border: 2px solid ${theme.colors.gray};
  width: 490px;
  height: 83px;
  border-radius: 10px;
  font-size: 25px;
  font-family: 'GmarketSansMedium';
  padding: 23px 26px;
  color: #777;
`;

const FileText = styled.p`
  width: 370px;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  margin: 0;
  overflow: hidden;
`;

const InputDisplay = styled.input`
  display: none;
`;

export default ResetSitterInfoAdd;

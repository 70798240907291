import Navbar from 'components/templates/Navbar';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import bgMenuCommon from 'resources/img/bg_menu_common.png';
import bgMenuCommonMobile from 'resources/img/bg_menu_common_mobile.png';
import icnHome from 'resources/img/icn_home.png';
import icnArrowRight from 'resources/img/icn_arrow_right.png';
import Footer from 'components/templates/Footer';
import icnWarn from 'resources/img/icn_warn.png';
import emoji1 from 'resources/img/emoji_1.png';
import emoji2 from 'resources/img/emoji_2.png';
import { useNavigate } from 'react-router-dom';

const CustomerServiceDetail = () => {
  const navigate = useNavigate();

  const data = { id: 1, title: '[글로벌시터 가이드] 글로벌 시터 회원으로 가입하기' };

  const handleClickList = () => {
    navigate('/customerService');
  };

  return (
    <>
      <Navbar active={'customerService'} />
      <Wrap>
        <CurrentMenu image={bgMenuCommon} mobileImage={bgMenuCommonMobile}>
          <IndicatorContainer>
            <IndicatorFirst src={icnHome} />
            <IndicatorArrow src={icnArrowRight} />
            <IndicatorCurrent>고객센터</IndicatorCurrent>
          </IndicatorContainer>
          고객센터
        </CurrentMenu>
        <ContentContainer>
          <Left>
            <Title>
              [시터회원 가이드] 부모님에게 직접 인터뷰 지원하기 받은 면접 제안에 수락*조율 하기
            </Title>
            <Contents>
              <SubTitle>1. 부모님에게 인터뷰 지원하기</SubTitle>
              <SubText>
                직접 지역, 시간, 아이정보 등 다양하게 검색하여 나에게 꼭 맞는 부모님을 찾아 인터뷰를
                지원 해보세요.
                <br />
                시터님이 인터뷰를 지원하면 부모님은 24시간 이내 수락/거절 로 응답할 수 있습니다.
                <br />
                내가 지원한 부모님이 24시간 안에 수락하는 하는 경우, 서로 연락할 수 있는 채팅창이
                활성화됩니다.
                <br />
                ([마이페이지 &gt; 채팅/구직현황] 에서 채팅창을 확인할 수 있습니다.)
              </SubText>
              <SubTitle>2. 부모님의 면접제안에 수락/조율하기</SubTitle>
              <SubText>
                부모님들도 시터님이 작성한 자기소개를 보고 신청할 수 있어요 ^ ^<br />
                시터님께서 알림을 놓치지 않으시도록 APP 푸시 알림 및 카카오톡 알림으로 부모님께서
                면접을 제안하시면 알림을 통해 즉시 알려드리고 있습니다!
                <br />
                부모님의 신청서를 꼼꼼히 읽고 반드시 3시간 안에 인터뷰 수락/조율/거절 등으로 응답
                해주세요.
              </SubText>
              <WarningBox>
                <WarnImg src={icnWarn} />
                인터뷰를 수락하고 일방적으로 취소하는 경우에는 신고 사유가 될 수 있으니, 신중하게
                수락해주세요.
              </WarningBox>
            </Contents>
            <HelpBox>
              <HelpTitle>도움이 되었습니까?</HelpTitle>
              <HelpButton>
                <Emoji src={emoji1} />
                도움이 되었습니다.
              </HelpButton>
              <HelpButton>
                <Emoji src={emoji2} />
                도움이 되지 않았습니다.
              </HelpButton>
              <HelpText>
                312명 중 <HelpTextBold>277</HelpTextBold>명이 도움이 되었다고 하였습니다.
              </HelpText>
              <HelpText2>
                또 다른 질문이 있으십니까? <HelpRegister>문의 등록</HelpRegister>
              </HelpText2>
            </HelpBox>
            <ListButton onClick={handleClickList}>목록</ListButton>
          </Left>
          <Right>
            <RightItemContainer>
              <SubTitle2>최근 본 문서</SubTitle2>
              <Item2>[글로벌시터 가이드] 글로벌 시터 회원으로 가입하기</Item2>
              <Item2>[글로벌시터 가이드] 글로벌시터는 어떤 서비스인가요?</Item2>
              <Item2>[글로벌시터 가이드] 연결률 높이는 프로필 작성하기</Item2>
              <Item2>[글로벌시터 가이드] 글로벌 시터 인증받기</Item2>
            </RightItemContainer>
            <RightItemContainer>
              <SubTitle2>관련 문서</SubTitle2>
              <Item2>[글로벌시터 가이드] 글로벌 시터 회원으로 가입하기</Item2>
              <Item2>[글로벌시터 가이드] 글로벌시터는 어떤 서비스인가요?</Item2>
              <Item2>[글로벌시터 가이드] 연결률 높이는 프로필 작성하기</Item2>
              <Item2>[글로벌시터 가이드] 글로벌 시터 인증받기</Item2>
            </RightItemContainer>
          </Right>
        </ContentContainer>
      </Wrap>
      <Footer />
    </>
  );
};

const Wrap = styled.div`
  padding: 106px 0 0 0;

  @media ${theme.laptop} {
    padding: 60px 0 0 0;
  }
`;

const CurrentMenu = styled.div`
  height: 320px;
  color: white;
  font-family: 'NPSfontEB';
  font-size: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  ${(props) =>
    css`
      background-image: url(${props.image});
    `}

  @media ${theme.desktop} {
    height: 240px;
    font-size: 36px;

    ${(props) =>
      css`
        background-image: url(${props.mobileImage});
      `}
  }
`;

const IndicatorContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 30px;
  display: flex;
`;

const IndicatorFirst = styled.img`
  height: 20px;
`;

const IndicatorArrow = styled.img``;

const IndicatorCurrent = styled.span`
  font-size: 13px;
  color: white;
  font-family: 'NanumSquareRoundEB';
  line-height: 20px;
`;

const ContentContainer = styled.div`
  width: 1280px;
  margin: 80px auto 0px;
  text-align: left;
  padding: 34px 40px 80px;
  display: flex;

  @media ${theme.desktop} {
    width: inherit;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 25px;
    margin-bottom: 80px;
    border-radius: 10px;
    padding: 24px 10px;
  }

  @media ${theme.laptop} {
    display: block;
  }
`;

const Title = styled.div`
  color: #383b40;
  font-size: 38px;
  font-family: 'NanumSquareRoundEB';

  @media ${theme.laptop} {
    font-size: 24px;
  }
`;

const Left = styled.div`
  flex: 5;
  padding-right: 80px;

  @media ${theme.laptop} {
    padding-right: 0px;
  }
`;

const Contents = styled.div``;

const SubTitle = styled.div`
  font-family: 'NanumSquareRoundEB';
  font-size: 18px;
  margin-top: 45px;
`;

const SubText = styled.div`
  font-family: 'NanumSquareRoundR';
  font-size: 18px;
  margin-top: 16px;
`;

const WarningBox = styled.div`
  background: #fff0f0;
  padding: 18px 24px;
  color: #ff3838;
  font-size: 14px;
  font-family: 'NanumSquareRoundEB';
  margin-top: 42px;
  display: flex;
  align-items: center;
`;

const WarnImg = styled.img`
  with: 20px;
  height: 20px;
  margin-right: 8px;
`;

const HelpBox = styled.div`
  border-top: 1px solid #e5e8ec;
  border-bottom: 1px solid #e5e8ec;
  text-align: center;
  margin-top: 55px;
  padding-top: 30px;
  padding-bottom: 30px;
`;

const HelpTitle = styled.div`
  color: #383b40;
  font-size: 18px;
  font-family: 'NanumSquareRoundEB';
`;

const HelpButton = styled.button`
  margin-top: 25px;
  background: white;
  border: 1px solid #d8dadd;
  border-radius: 30px;
  margin-left: 8px;
  margin-right: 8px;
  width: 240px;
  height: 56px;
  color: #383b40;
  font-size: 16px;
  font-family: 'NanumSquareRoundEB';
`;

const Emoji = styled.img`
  vertical-align: middle;
  margin-right: 10px;
`;

const HelpText = styled.div`
  margin-top: 22px;
  color: #383b40;
  font-size: 14px;
  font-family: 'NanumSquareRoundR';
`;

const HelpTextBold = styled.span`
  font-family: 'NanumSquareRoundEB';
`;

const HelpText2 = styled.div`
  margin-top: 32px;
  color: #383b40;
  font-size: 18px;
  font-family: 'NanumSquareRoundEB';
`;

const HelpRegister = styled.span`
  color: #20c673;
  cursor: pointer;
`;

const ListButton = styled.button`
  background: #20c673;
  color: white;
  font-family: 'NanumSquareRoundEB';
  font-size: 18px;
  margin-top: 40px;
  width: 100%;
  height: 64px;
  border-radius: 32px;
`;

const Right = styled.div`
  flex: 2;

  @media ${theme.laptop} {
    margin-top: 50px;
  }
`;

const SubTitle2 = styled.div`
  font-family: 'NanumSquareRoundEB';
  font-size: 18px;
  margin-bottom: 18px;

  @media ${theme.laptop} {
    font-size: 14px;
  }
`;

const RightItemContainer = styled.div`
  margin-bottom: 60px;

  @media ${theme.laptop} {
    margin-bottom: 30px;
  }
`;

const Item2 = styled.div`
  font-family: 'NanumSquareRoundR';
  font-size: 12px;
  margin-top: 8px;
  cursor: pointer;
`;

export default CustomerServiceDetail;

import { postSitterUsersAuthApi } from 'api/API';
import { makeAutoObservable, runInAction } from 'mobx';

class AuthStore {
  constructor() {
    makeAutoObservable(this);
  }

  postUserLogout = () => {
    localStorage.clear(); 
    window.location.href = '/';
  }

  authentication = async (data) => {
    await postSitterUsersAuthApi(data)
      .then((res) => {
        console.log(res);
        if ('SUCCESS' !== res.data.responseCode) {
          alert('요청에 실패했습니다.');
        } else {
          localStorage.setItem('userType', res.data.data.userType);
          localStorage.setItem('token', res.data.data.accessToken);
          alert('요청에 성공했습니다.');
          window.location.href = '/';
        }
      })
      .catch((error) => {
        console.log({...error})
        if (error.response.status === 401) {
          alert('아이디 비밀번호가 일치하지 않습니다.');
        } else {
          alert('요청에 실패했습니다.');
        }
      });
  };
}

const authStore = new AuthStore();

export default authStore;

import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import Navbar from 'components/templates/Navbar';
import Footer from 'components/templates/Footer';
import DaumPostcode from 'react-daum-postcode';
import Checkbox from 'components/atoms/Checkbox';
import LabeledInput from 'components/atoms/LabeledInput';
import stores from 'stores';

const ApplicationSitter = () => {
  const { parentStore, commonStore } = stores;
  const [inputs, setInputs] = useState({
    name: '',
    email: '',
    nationality: '',
    password: '',
    passwordCheck: '',
    birth: '',
    contact: '',
    address: '',
    addressCode: '',
    sido: '',
    sigungu: '',
    sigunguCode: '',
    detailAddress: '',
    certificationCode: '',
    workingHours: '',
    hopeSalary: '',
    specialNote: '',
  });

  const {
    name,
    nationality,
    birth,
    contact,
    email,
    address,
    addressCode,
    sido,
    sigungu,
    sigunguCode,
    detailAddress,
    certificationCode,
    workingHours,
    hopeSalary,
    specialNote,
  } = inputs;

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const onBirthChange = (e) => {
    const { value, name } = e.target;
    // console.log(value, name);
    let birthFormatted = '';
    let onlyNumber = value.replaceAll('-', '');
    if (onlyNumber.length > 6) {
      birthFormatted = `${onlyNumber.substring(0, 4)}-${onlyNumber.substring(
        4,
        6,
      )}-${onlyNumber.substring(6, 8)}`;
    } else if (onlyNumber.length > 4) {
      birthFormatted = `${onlyNumber.substring(0, 4)}-${onlyNumber.substring(4, 6)}`;
    } else {
      birthFormatted = onlyNumber.substring(0, 4);
    }
    setInputs({
      ...inputs,
      [name]: birthFormatted,
    });
  };

  const onPayChange = (e) => {
    const { value, name } = e.target;
    let formatted = !value?.replace(/\D/g, '')
      ? ''
      : `${parseInt(value.replace(/\D/g, '')).toLocaleString()}`;

    setInputs({
      ...inputs,
      [name]: formatted,
    });
  };

  const [showPostCode, setShowPostCode] = useState(false);

  const handleAddressComplete = (data) => {
    setInputs({
      ...inputs,
      address: data.address,
      addressCode: data.zonecode,
      sido: data.sido,
      sigungu: data.sigungu,
      sigunguCode: data.sigunguCode,
    });
    setShowPostCode(false);
  };

  const postCodeStyle = {
    display: 'block',
    position: 'relative',
    top: '17px',
    left: '0',
    width: '700px',
    height: '400px',
    zIndex: 100,
    border: '2px solid #bcbcbc',
    borderRadius: '10px',
    padding: '5px',
  };

  const numberOfChild = [
    { value: '1', text: '1명' },
    { value: '2', text: '2명' },
    { value: '3', text: '3명' },
  ];

  const [selectedChildsNum, setSelectedChildsNum] = useState('');

  const handleChildsNum = (e) => {
    const { value, checked } = e.target;
    setSelectedChildsNum(value);
  };

  const childsAge = [
    { value: 'NEWBORN', text: '신생아', subText: '(출생 후 1주일 ~ 1개월 된 아기)' },
    { value: 'BABY_BEFORE_WALK', text: '영아', subText: '(걷기 전 단계의 아기)' },
    { value: 'BABY_BEFORE_6', text: '유아', subText: '(만 6세까지의 아이)' },
    { value: 'SCHOOL_CHILD', text: '초등학생', subText: '(7세부터 13세까지의 아이)' },
  ];

  const [selectedChildsAge, setSelectedChildsAge] = useState([]);

  const handleChildsAge = (e) => {
    const { value, checked } = e.target;
    setSelectedChildsAge([...selectedChildsAge, value]);
    if (!checked) {
      setSelectedChildsAge(selectedChildsAge.filter((item) => item !== value));
    }
  };

  const [privacyCheck, setPrivacyCheck] = useState(false);

  const handleChangePrivacyCheck = () => {
    setPrivacyCheck(!privacyCheck);
  };

  const [checkCertification, setCheckCertification] = useState(false);

  useEffect(() => {
    console.log('commonStore.checkCertification', commonStore.checkCertification);
    if (commonStore.checkCertification === true) {
      setCheckCertification(true);
    }
  }, [commonStore.checkCertification]);

  const [showCertification, setShowCertification] = useState(false);
  const handleCertification = () => {
    commonStore.postCertificationCode({ cellphone: contact });
    setShowCertification(true);
  };

  const handleCheckCertificationCode = () => {
    commonStore.postCertification({ cellphone: contact, code: certificationCode });
  };

  const handleSubmit = () => {
    if (
      !name ||
      !contact ||
      !checkCertification ||
      !email ||
      !birth ||
      !addressCode ||
      !detailAddress ||
      !selectedChildsNum ||
      !selectedChildsAge ||
      !workingHours ||
      !hopeSalary ||
      !specialNote
    ) {
      alert('필수 값을 입력해 주세요.');
      return;
    }

    if (!privacyCheck) {
      alert('약관에 동의해 주세요.');
      return;
    }

    const data = {
      name: inputs.name,
      cellphone: inputs.contact,
      email: inputs.email,
      address: inputs.address,
      addressCode: inputs.addressCode,
      addressDetails: inputs.detailAddress,
      sido: inputs.sido,
      sigungu: inputs.sigungu,
      sigunguCode: inputs.sigunguCode,
      birthDate: inputs.birth,
      babyTypes: selectedChildsAge,
      numberOfBabies: selectedChildsNum,
      businessHour: workingHours,
      nationalityEtc: inputs.nationality,
      availableHourlyWage: hopeSalary.replace(/\D/g, ''),
      introduction: specialNote,
    };
    parentStore.postParentSitterRequestsApi(data);
  };

  return (
    <>
      <Navbar />
      <Wrap>
        <Title>시터 신청하기</Title>
        <Form>
          <LabeledInput
            label="이름"
            name="name"
            value={name}
            placeholder="이름을 입력해주세요"
            onChange={onChange}
          />
          <LabelInput style={{ marginTop: 80 }}>
            <Label>연락처</Label>
            <ContactInputWrap>
              <Input
                name="contact"
                value={contact}
                placeholder="연락처를 입력해주세요"
                onChange={onChange}
                disabled={checkCertification}
              />
              <ContactInputButton type="button" onClick={() => handleCertification()}>
                번호 인증
              </ContactInputButton>
            </ContactInputWrap>
            {showCertification && (
              <ContactInputWrap>
                <Input
                  name="certificationCode"
                  value={certificationCode}
                  placeholder="인증번호를 입력해주세요"
                  onChange={onChange}
                  disabled={checkCertification}
                />
                <ContactInputButton type="button" onClick={() => handleCheckCertificationCode()}>
                  인증번호 확인
                </ContactInputButton>
              </ContactInputWrap>
            )}
          </LabelInput>
          <LabeledInput
            label="이메일"
            name="email"
            value={email}
            placeholder="이메일을 입력해주세요"
            onChange={onChange}
          />
          <LabeledInput
            label="생년월일"
            name="birth"
            value={birth}
            placeholder="생년월일을 입력해주세요"
            onChange={onBirthChange}
          />
          <LabelInput style={{ marginTop: 80 }}>
            <Label>주소</Label>
            <ZipCode>
              <ZipCodeInput
                name="zipCode"
                placeholder="우편번호"
                defaultValue={inputs.zipcode}
                disabled
              />
              <ZipCodeButton type="button" onClick={() => setShowPostCode(!showPostCode)}>
                우편번호
              </ZipCodeButton>
            </ZipCode>
            <AddressInput placeholder="주소" defaultValue={inputs.address} disabled />
            <AddressInput
              name="detailAddress"
              value={detailAddress}
              placeholder="상세주소를 입력해주세요"
              onChange={onChange}
            />
            {showPostCode ? (
              <div>
                <DaumPostcode onComplete={handleAddressComplete} style={postCodeStyle} />
              </div>
            ) : (
              <></>
            )}
          </LabelInput>
          <Title>추가 정보 입력</Title>
          <LabelInput>
            <Label>자녀가 몇 명인지 입력해주세요.</Label>
            <CheckboxWrapFlex>
              {numberOfChild.map((item) => (
                <CheckboxFlexBox>
                  <Checkbox
                    key={item.value}
                    defaultValue={item.value}
                    type="checkbox"
                    name="numberOfChildGroup"
                    handleClick={(e) => handleChildsNum(e)}
                    isChecked={selectedChildsNum === item.value}
                  >
                    {item.text}
                  </Checkbox>
                </CheckboxFlexBox>
              ))}
            </CheckboxWrapFlex>
          </LabelInput>
          <LabelInput>
            <Label>
              자녀의 나이대를 입력해주세요. <span>(중복 선택 가능)</span>
            </Label>
            <CheckboxWrap>
              {childsAge.map((item) => (
                <Checkbox
                  key={item.value}
                  defaultValue={item.value}
                  type="checkbox"
                  name="childsAgeGroup"
                  handleClick={(e) => handleChildsAge(e)}
                  isChecked={selectedChildsAge.includes(item.value)}
                >
                  {item.text}
                  <CheckboxSubText>{item.subText}</CheckboxSubText>
                </Checkbox>
              ))}
            </CheckboxWrap>
          </LabelInput>
          <LabeledInput
            label="원하는 시터의 국적을 입력해주세요"
            name="nationality"
            value={nationality}
            placeholder="국적을 입력해주세요."
            onChange={onChange}
          />
          <LabeledInput
            label="시터의 근로시간을 입력해주세요."
            name="workingHours"
            value={workingHours}
            placeholder="예시) 월~금 오전 9시부터 오후 6시까지"
            onChange={onChange}
          />
          <LabelInput style={{ marginTop: 80 }}>
            <Label>시터에게 지급 가능한 시급을 입력해주세요.</Label>
            <SmallInput
              name="hopeSalary"
              value={hopeSalary}
              placeholder="최저임금은 9,160원입니다."
              onChange={onPayChange}
            />
            <MoneyIcon>₩</MoneyIcon>
          </LabelInput>
          <LabelInput>
            <Label>시터가 알아야 할 내용이 있다면 입력해주세요.</Label>
            <Textarea
              name="specialNote"
              value={specialNote}
              placeholder="아이의 성별, 나이, 성격, 특이사항 등을 입력해주세요."
              onChange={onChange}
            />
          </LabelInput>
          <PrivacyNotice>
            &lt;개인정보 처리 방침&gt; 수강생 출석 확인과 서비스 품질 개선 목적으로 ZOOM 강의 영상을
            실시간녹화하고 있습니다. 해당 녹화본은 수집 목적 외 용도로 사용되지 않으며, 보유기간이
            지난 후 즉시 폐기됩니다.
          </PrivacyNotice>
          <Checkbox
            type="checkbox"
            name="privacyCheck"
            handleClick={handleChangePrivacyCheck}
            isChecked={privacyCheck}
          >
            개인정보 수집/이용 동의
          </Checkbox>
          <SubmitButton type="button" onClick={() => handleSubmit()}>
            신청하기
          </SubmitButton>
        </Form>
      </Wrap>
      <Footer />
    </>
  );
};
const Wrap = styled.div`
  padding: 106px 0 0 0;

  @media ${theme.laptop} {
    padding: 60px 0 0 0;
  }
`;
const Title = styled.p`
  color: ${theme.colors.deepGreen};
  font-family: 'GmarketSansMedium';
  font-size: 50px;
  letter-spacing: -1.25px;
  text-align: center;
  margin: 100px 0 100px 0;
`;

const Description = styled.p`
  font-size: 25px;
  letter-spacing: -1.25px;
  text-align: center;
  margin: 0;
  color: ${theme.colors.deepGreen};
  font-family: 'GmarketSansMedium';
`;

const Form = styled.form`
  width: 700px;
  margin: 80px auto 0;
`;

const LabelInput = styled.div`
  margin: 0 0 100px 0;
  position: relative;
`;

const Label = styled.p`
  font-size: 30px;
  line-height: 30px;
  font-family: 'GmarketSansMedium';
  letter-spacing: -1.5px;
  color: #2d2d2d;
  margin: 0 0 17px 7px;

  & > span {
    font-size: 20px;
    font-family: 'GmarketSansLight';
  }
`;

const Input = styled.input`
  border: 2px solid ${theme.colors.gray};
  width: 100%;
  height: 83px;
  border-radius: 10px;
  font-size: 25px;
  font-family: 'GmarketSansMedium';
  padding: 0 26px;

  &::placeholder {
    color: ${theme.colors.gray};
  }

  &:disabled {
    background-color: #f4f4f4;
    color: #bcbcbc;
  }
`;

const ContactInputWrap = styled.div`
  display: flex;

  & + & {
    margin-top: 10px;
  }
`;

const ZipCode = styled.div`
  display: flex;
`;

const ZipCodeInput = styled(Input)``;

const ZipCodeButton = styled.button`
  height: 83px;
  border: 2px solid ${theme.colors.gray};
  border-radius: 10px;
  font-size: 21px;
  font-family: 'GmarketSansMedium';
  color: #848484;
  background-color: white;
  width: 200px;
  margin: 0 0 0 15px;
`;

const ContactInputButton = styled(ZipCodeButton)``;

const AddressInput = styled(Input)`
  margin: 17px 0 0 0;
`;

const PrivacyNotice = styled.p`
  font-family: 'GmarketSansLight';
  font-size: 22px;
  line-height: 46px;
  letter-spacing: -1.1px;
  color: #353535;
`;

const SubmitButton = styled.button`
  border: 4px solid ${theme.colors.primary};
  width: 200px;
  height: 84px;
  border-radius: 50px;
  background-color: white;
  margin: 115px auto 93px;
  font-size: 30px;
  color: ${theme.colors.primary};
  letter-spacing: -0.75px;
  font-family: 'GmarketSansMedium';
  display: block;
`;

const CheckboxWrap = styled.div`
  margin: 40px 0 0 7px;
`;

const CheckboxWrapFlex = styled(CheckboxWrap)`
  display: flex;
`;

const CheckboxFlexBox = styled.div`
  width: 250px;
`;

const CheckboxSubText = styled.span`
  font-size: 20px;
  font-family: 'GmarketSansLight';
  margin: 0 0 0 15px;
`;

const SmallInput = styled(Input)`
  width: 490px;
`;

const MoneyIcon = styled.span`
  font-size: 30px;
  font-family: 'GmarketSansMedium';
  margin-left: 15px;
`;

const Textarea = styled.textarea`
  border: 2px solid ${theme.colors.gray};
  width: 100%;
  height: 300px;
  border-radius: 10px;
  font-size: 25px;
  font-family: 'GmarketSansMedium';
  padding: 20px 26px;
  resize: none;
  box-sizing: border-box;

  &::placeholder {
    color: ${theme.colors.gray};
  }

  &:disabled {
    background-color: #f4f4f4;
    color: #bcbcbc;
  }
`;

export default ApplicationSitter;

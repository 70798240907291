import React from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import { Link, Routes, Route, Outlet } from 'react-router-dom';
import Navbar from 'components/templates/Navbar';
import Footer from 'components/templates/Footer';

import mainPageImg0 from 'resources/img/main_img.png';
import mainStep1Arrow from 'resources/img/btn_main_step1.png';
import mainPagingStop from 'resources/img/icn_stop.png';
import mainCat1 from 'resources/img/main_cat_1.png';
import mainCat2 from 'resources/img/main_cat_2.png';
import mainCat3 from 'resources/img/main_cat_3.png';
import mainCat4 from 'resources/img/main_cat_4.png';
import mainCat5 from 'resources/img/main_cat_5.png';
import step3Bg from 'resources/img/step3_bg.png';
import step3Img1 from 'resources/img/step_3_img_1.png';
import step3Img2 from 'resources/img/step_3_img_2.png';
import step3Img3 from 'resources/img/step_3_img_3.png';
import step3Img4 from 'resources/img/step_3_img_4.png';
import step4Img1 from 'resources/img/step_4_img_1.png';
import step5Img1 from 'resources/img/step_5_img_1.png';
import step5Img2 from 'resources/img/step_5_img_2.png';
import step5Img3 from 'resources/img/step_5_img_3.png';
import step5Img4 from 'resources/img/step_5_img_4.png';
import arrowDownImg from 'resources/img/arrow_down.png';

const Main = () => {
  return (
    <>
      <Navbar />
      <Wrap>
        <MainSection>
          <MainInnerSection>
            <MainStep1Top>
              <MainStep1LeftContainer>
                <MainStep1Txt1>베이비시터는</MainStep1Txt1>
                <MainStep1Txt2>글로벌시터와 함께!</MainStep1Txt2>
                <MainStep1SubTxt>
                  글로벌시터는 출산도우미, 영유아&탁아/보육 서비스에
                  <br /> 새로운 패러다임을 만들어 가겠습니다.
                </MainStep1SubTxt>
                <MainStep1Btn>
                  자세히 보기
                  <MainStep1Img src={mainStep1Arrow} />
                </MainStep1Btn>
              </MainStep1LeftContainer>
              <MainImg src={mainPageImg0} alt="메인이미지임시" />
            </MainStep1Top>
            <Paging>
              <PagingStop src={mainPagingStop} />
              <PagingDot />
            </Paging>
          </MainInnerSection>
        </MainSection>
        <MainStep2>
          <MainStep2Inner>
            <MainStep2Title>글로벌시터 카테고리</MainStep2Title>
            <MainStep2Content>
              <MainStep2ContentItem>
                <MainStep2CatImg src={mainCat1} />
                <MainStep2CatTxt>이용절차</MainStep2CatTxt>
              </MainStep2ContentItem>
              <MainStep2ContentItem>
                <MainStep2CatImg src={mainCat2} />
                <MainStep2CatTxt>이용금액</MainStep2CatTxt>
              </MainStep2ContentItem>
              <MainStep2ContentItem>
                <MainStep2CatImg src={mainCat3} />
                <MainStep2CatTxt>가능지역</MainStep2CatTxt>
              </MainStep2ContentItem>
              <MainStep2ContentItem>
                <MainStep2CatImg src={mainCat4} />
                <MainStep2CatTxt>자주하는 질문</MainStep2CatTxt>
              </MainStep2ContentItem>
              <MainStep2ContentItem>
                <MainStep2CatImg src={mainCat5} />
                <MainStep2CatTxt>계약 약관</MainStep2CatTxt>
              </MainStep2ContentItem>
            </MainStep2Content>
          </MainStep2Inner>
        </MainStep2>
        <MainStep3 image={step3Bg}>
          <MainStep3Inner>
            <MainStep3Title>
              글로벌시터는 최고의 <br />
              베이비시팅 서비스를 제공합니다
            </MainStep3Title>
            <MainStep3Content>
              <MainStep3Card>
                <MainStep3Img src={step3Img1} />
                <MainStep3ItemContentContainer>
                  <MainStep3ItemTitle>일반 베이비시터</MainStep3ItemTitle>
                  <MainStep3ItemContent>일반 베이비시터</MainStep3ItemContent>
                  <MainStep3ItemButton>서비스 보러가기</MainStep3ItemButton>
                </MainStep3ItemContentContainer>
              </MainStep3Card>
              <MainStep3Card>
                <MainStep3Img src={step3Img2} />
                <MainStep3ItemContentContainer>
                  <MainStep3ItemTitle>입주 베이비 시터</MainStep3ItemTitle>
                  <MainStep3ItemContent>고객님 댁에 입주 베이비시터</MainStep3ItemContent>
                  <MainStep3ItemButton>서비스 보러가기</MainStep3ItemButton>
                </MainStep3ItemContentContainer>
              </MainStep3Card>
              <MainStep3Card>
                <MainStep3Img src={step3Img3} />
                <MainStep3ItemContentContainer>
                  <MainStep3ItemTitle>단기 베이비 시터</MainStep3ItemTitle>
                  <MainStep3ItemContent>등원, 하원 도우미</MainStep3ItemContent>
                  <MainStep3ItemButton>서비스 보러가기</MainStep3ItemButton>
                </MainStep3ItemContentContainer>
              </MainStep3Card>
              <MainStep3Card>
                <MainStep3Img src={step3Img4} />
                <MainStep3ItemContentContainer>
                  <MainStep3ItemTitle>그룹 베이비 시터</MainStep3ItemTitle>
                  <MainStep3ItemContent>연령별 그룹 베이비 시터</MainStep3ItemContent>
                  <MainStep3ItemButton>서비스 보러가기</MainStep3ItemButton>
                </MainStep3ItemContentContainer>
              </MainStep3Card>
            </MainStep3Content>
          </MainStep3Inner>
        </MainStep3>
        <MainStep4>
          <MainStep4Inner>
            <MainStep4Title>글로벌시터의 교재를 소개합니다.</MainStep4Title>
            <MainStep4ContentContainer>
              <MainStep4ImageContainer>
                <MainStep4Image src={step4Img1} />
              </MainStep4ImageContainer>
              <MainStep4Content>
                <MainStep4ContentSubtitle>글로벌시터 교재</MainStep4ContentSubtitle>
                <MainStep4ContentTxt>
                  글로벌시터가 직접 개발하고 운영하고 있는 이중언어 아이돌봄 시터를
                  <br /> 교육과정에서 사용되는 교재입니다.
                  <br /> 한국어와 영어로 통합 제작되었으며 별도의 중국어 판을 사용하고 있습니다.
                  <br /> 주 5일, 20시간 과정으로 외국인을 대상으로 교육을 하고 있으며,
                  <br /> 교육 역시 한국어, 영어, 중국어로 각각 진행되고 있습니다.
                </MainStep4ContentTxt>
                <MainStep4Button>교재 구입하기</MainStep4Button>
              </MainStep4Content>
            </MainStep4ContentContainer>
          </MainStep4Inner>
        </MainStep4>
        <MainStep5>
          <MainStep5Inner>
            <MainStep5Title>
              글로벌시터의 따뜻한 교육현장을
              <br />
              담아보았습니다.
            </MainStep5Title>
            <MainStep5Content>
              <MainStep5Card>
                <MainStep5Img src={step5Img1} />
                <MainStep5ItemContentContainer>
                  <MainStep5ItemTitle>
                    2023년 11월 베이비시터 <br />
                    자격증 교육 안내
                  </MainStep5ItemTitle>
                  <MainStep5ItemContent>
                    2023년 11월 20일(월) ~ 11월 23일(목) <br />
                    베이비플래너(전문베이비시터) 자격증 취득 ...
                  </MainStep5ItemContent>
                  <MainStep5ItemDate>1주전</MainStep5ItemDate>
                </MainStep5ItemContentContainer>
              </MainStep5Card>
              <MainStep5Card>
                <MainStep5Img src={step5Img2} />
                <MainStep5ItemContentContainer>
                  <MainStep5ItemTitle>
                    2023년 11월 베이비시터 <br />
                    자격증 교육 안내
                  </MainStep5ItemTitle>
                  <MainStep5ItemContent>
                    2023년 11월 20일(월) ~ 11월 23일(목) <br />
                    베이비플래너(전문베이비시터) 자격증 취득 ...
                  </MainStep5ItemContent>
                  <MainStep5ItemDate>1주전</MainStep5ItemDate>
                </MainStep5ItemContentContainer>
              </MainStep5Card>
              <MainStep5Card>
                <MainStep5Img src={step5Img3} />
                <MainStep5ItemContentContainer>
                  <MainStep5ItemTitle>
                    2023년 11월 베이비시터 <br />
                    자격증 교육 안내
                  </MainStep5ItemTitle>
                  <MainStep5ItemContent>
                    2023년 11월 20일(월) ~ 11월 23일(목) <br />
                    베이비플래너(전문베이비시터) 자격증 취득 ...
                  </MainStep5ItemContent>
                  <MainStep5ItemDate>1주전</MainStep5ItemDate>
                </MainStep5ItemContentContainer>
              </MainStep5Card>
              <MainStep5Card>
                <MainStep5Img src={step5Img4} />
                <MainStep5ItemContentContainer>
                  <MainStep5ItemTitle>
                    2023년 11월 베이비시터 <br />
                    자격증 교육 안내
                  </MainStep5ItemTitle>
                  <MainStep5ItemContent>
                    2023년 11월 20일(월) ~ 11월 23일(목) <br />
                    베이비플래너(전문베이비시터) 자격증 취득 ...
                  </MainStep5ItemContent>
                  <MainStep5ItemDate>1주전</MainStep5ItemDate>
                </MainStep5ItemContentContainer>
              </MainStep5Card>
            </MainStep5Content>
            <MoreButton>
              더보기 <ArrowDownImg src={arrowDownImg} />
            </MoreButton>
          </MainStep5Inner>
        </MainStep5>
      </Wrap>
      <Footer />
    </>
  );
};

const Wrap = styled.div`
  padding: 106px 0 0 0;

  @media ${theme.laptop} {
    padding: 60px 0 0 0;
  }
`;

const MainSection = styled.div`
  width: 100%;
  height: 700px;
  background-color: ${theme.colors.primary};
  position: relative;
  overflow: hidden;
  padding-top: 120px;

  @media ${theme.desktop} {
    padding-top: 55px;
    padding-bottom: 50px;
    height: inherit;
  }
`;

const MainInnerSection = styled.div`
  width: 1280px;
  margin: 0 auto;
  justify-content: space-between;

  @media ${theme.desktop} {
    width: 100%;
    display: block;
    text-align: center;
  }
`;

const MainStep1Top = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${theme.desktop} {
    display: block;
  }
`;

const MainStep1LeftContainer = styled.div`
  text-align: left;
  @media ${theme.desktop} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const MainStep1Txt1 = styled.div`
  color: white;
  font-family: 'NPSfontEB';
  font-size: 60px;
  line-height: 115%;

  @media ${theme.desktop} {
    font-size: 32px;
  }
`;

const MainStep1Txt2 = styled.div`
  color: #ffde00;
  font-family: 'NPSfontEB';
  font-size: 60px;
  letter-spacing: -0.05em;
  line-height: 115%;

  @media ${theme.desktop} {
    font-size: 32px;
  }
`;
const MainStep1SubTxt = styled.div`
  color: white;
  font-size: 20px;
  margin-top: 40px;
  line-height: 120%;
  font-family: 'NanumSquareRoundEB';

  @media ${theme.desktop} {
    font-size: 16px;
    margin-top: 25px;
  }
`;

const MainStep1Btn = styled.button`
  color: ${theme.colors.primary};
  font-family: 'NanumSquareRoundEB';
  background: white;
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-top: 45px;

  @media ${theme.desktop} {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
`;

const Paging = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;

  @media ${theme.desktop} {
    margin-left: 24px;
    margin-right: 24px;
  }
`;

const PagingStop = styled.img``;

const PagingDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #ffde00;
  margin-left: 6px;
`;

const MainStep1Img = styled.img`
  width: 24px;
  height: 24px;
`;

const MainImg = styled.img`
  width: 754px;
  object-fit: contain;
  margin-top: -40px;

  @media ${theme.desktop} {
    width: 320px;
    height: 218px;
    margin-top: 30px;
    margin-left: auto;
    marign-right: auto;
  }
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const MainStep2 = styled.div`
  background: white;
  padding-top: 80px;
  padding-bottom: 80px;
`;

const MainStep2Inner = styled.div`
  width: 980px;
  margin: 0 auto;
  align-content: center;

  @media ${theme.desktop} {
    width: 100%;
  }
`;

const MainStep2Title = styled.div`
  font-family: 'NPSfontEB';
  color: #383b40;
  font-size: 40px;
  text-align: center;

  @media ${theme.desktop} {
    font-size: 24px;
  }
`;

const MainStep2Content = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 48px;

  @media ${theme.desktop} {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const MainStep2ContentItem = styled.div`
  cursor: pointer;
  margin-left: 12px;
  margin-right: 12px;
`;

const MainStep2CatImg = styled.img`
  width: 100px;
  height: 100px;
`;

const MainStep2CatTxt = styled.div`
  font-family: 'NanumSquareRoundEB';
  color: #383b40;
  font-size: 16px;
  margin-top: 12px;
  text-align: center;
`;

const MainStep3 = styled.div`
  padding-top: 80px;
  padding-bottom: 80px;
  ${(props) =>
    css`
      background-image: url(${props.image});
    `}
`;

const MainStep3Inner = styled.div`
  width: 1024px;
  margin: 0 auto;
  align-content: center;

  @media ${theme.desktop} {
    width: 100%;
  }
`;

const MainStep3Title = styled.div`
  font-family: 'NPSfontEB';
  color: #383b40;
  font-size: 40px;
  text-align: center;

  @media ${theme.desktop} {
    font-size: 24px;
  }
`;

const MainStep3Content = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 60px;

  @media ${theme.desktop} {
    display: block;
  }
`;

const MainStep3Card = styled.div`
  @media ${theme.desktop} {
    display: block;
    width: 320px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
  }
`;

const MainStep3Img = styled.img`
  width: 240px;
  @media ${theme.desktop} {
    width: 320px;
  }
`;

const MainStep3ItemContentContainer = styled.div`
  background: white;
  padding: 18px 18px;
  margin-top: -2px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;

const MainStep3ItemTitle = styled.div`
  font-family: 'NanumSquareRoundEB';
  font-size: 18px;
  text-align: center;
`;

const MainStep3ItemContent = styled.div`
  font-family: 'NanumSquareRoundR';
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
`;

const MainStep3ItemButton = styled.button`
  background-color: ${theme.colors.primary};
  font-family: 'NanumSquareRoundEB';
  font-size: 14px;
  color: white;
  border-radius: 22px;
  width: 100%;
  height: 43px;
  margin-top: 22px;
`;

const MainStep4 = styled.div`
  padding-top: 80px;
  padding-bottom: 80px;
`;

const MainStep4Inner = styled.div`
  width: 1024px;
  margin: 0 auto;
  align-content: center;

  @media ${theme.desktop} {
    width: 100%;
  }
`;

const MainStep4Title = styled.div`
  font-family: 'NPSfontEB';
  color: #383b40;
  font-size: 40px;
  text-align: center;

  @media ${theme.desktop} {
    font-size: 24px;
  }
`;

const MainStep4ContentContainer = styled.div`
  display: flex;
  margin-top: 80px;

  @media ${theme.desktop} {
    display: block;
  }
`;

const MainStep4ImageContainer = styled.div`
  background: #fffaeb;
  border-radius: 10px;
  padding: 38px 100px;

  @media ${theme.desktop} {
    padding: 38px 0px;
    text-align: center;
  }
`;

const MainStep4Image = styled.img``;

const MainStep4Content = styled.div`
  padding-left: 54px;
  padding-top: 90px;

  @media ${theme.desktop} {
    padding: 38px;
  }
`;

const MainStep4ContentSubtitle = styled.div`
  font-family: 'NPSfontEB';
  color: #383b40;
  font-size: 35px;

  @media ${theme.desktop} {
    font-size: 20px;
  }
`;

const MainStep4ContentTxt = styled.div`
  font-family: 'NanumSquareRoundR';
  margin-top: 12px;
  font-size: 15px;
  line-height: 160%;
`;

const MainStep4Button = styled.button`
  background: #20c673;
  height: 50px;
  width: 220px;
  border-radius: 30px;
  margin-top: 20px;
  font-family: 'NanumSquareRoundEB';
  font-size: 14px;
  color: white;

  @media ${theme.desktop} {
    width: 100%;
    margin-top: 30px;
  }
`;

const MainStep5 = styled.div`
  padding-top: 80px;
  padding-bottom: 80px;
`;

const MainStep5Inner = styled.div`
  width: 1024px;
  margin: 0 auto;
  align-content: center;

  @media ${theme.desktop} {
    width: 100%;
  }
`;

const MainStep5Title = styled.div`
  font-family: 'NPSfontEB';
  color: #383b40;
  font-size: 40px;
  text-align: center;

  @media ${theme.desktop} {
    font-size: 24px;
  }
`;

const MainStep5Content = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 60px;

  @media ${theme.desktop} {
    display: block;
  }
`;

const MainStep5Card = styled.div`
  border: 1px solid #d8dadd;
  border-radius: 12px;
  width: 240px;
  cursor: pointer;

  @media ${theme.desktop} {
    width: 320px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
  }
`;

const MainStep5Img = styled.img`
  width: 240px;

  @media ${theme.desktop} {
    width: 320px;
  }
`;

const MainStep5ItemContentContainer = styled.div`
  background: white;
  padding: 18px 18px;
  margin-top: -2px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;

const MainStep5ItemTitle = styled.div`
  font-family: 'NanumSquareRoundEB';
  font-size: 18px;
  color: #383b40;
  line-height: 120%;
`;

const MainStep5ItemContent = styled.div`
  font-family: 'NanumSquareRoundR';
  margin-top: 5px;
  font-size: 12px;
  color: #383b40;
  line-height: 130%;
`;

const MainStep5ItemDate = styled.div`
  font-family: 'NanumSquareRoundR';
  font-size: 11px;
  color: #747983;
  margin-top: 22px;
`;

const MoreButton = styled.button`
  border: 1px solid #383b40;
  border-radius: 24px;
  height: 40px;
  width: 160px;
  margin-top: 48px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  font-family: 'NanumSquareRoundR';
`;

const ArrowDownImg = styled.img`
  margin-left: 4px;
`;

export default Main;

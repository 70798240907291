import { createGlobalStyle } from 'styled-components';
import GmarketSansMedium from './GmarketSansMedium.woff';
import GmarketSansBold from './GmarketSansBold.woff';
import GmarketSansLight from './GmarketSansLight.woff';
import DongleReqular from './Dongle-Regular.woff';
import NanumSquareRoundEB from './NanumSquareRoundEB.ttf';
import NanumSquareRoundR from './NanumSquareRoundR.ttf';
import NPSfontEB from './NPSfont_extrabold.ttf';
import PretendardR from './Pretendard-Regular.woff';

export default createGlobalStyle`
    @font-face {
        font-family: "GmarketSansMedium";
        src: local("GmarketSansMedium"),
        url(${GmarketSansMedium}) format('woff');
        font-style: normal;
    }
    @font-face {
        font-family: "GmarketSansBold";
        src: local("GmarketSansBold"),
        url(${GmarketSansBold}) format('woff');
        font-style: normal;
    }
    @font-face {
        font-family: "DongleReqular";
        src: local("DongleReqular"),
        url(${DongleReqular}) format('woff');
        font-style: normal;
    }
    @font-face {
        font-family: "GmarketSansLight";
        src: local("GmarketSansLight"),
        url(${GmarketSansLight}) format('woff');
        font-style: normal;
    }
    @font-face {
        font-family: "GmarketSansLight";
        src: local("GmarketSansLight"),
        url(${GmarketSansLight}) format('woff');
        font-style: normal;
    }
    @font-face {
        font-family: "NanumSquareRoundEB";
        src: local("NanumSquareRoundEB"),
        url(${NanumSquareRoundEB}) format('truetype');
        font-style: normal;
    }
    @font-face {
        font-family: "NanumSquareRoundR";
        src: local("NanumSquareRoundR"),
        url(${NanumSquareRoundR}) format('truetype');
        font-style: normal;
    }
    @font-face {
        font-family: "NPSfontEB";
        src: local("NPSfontEB"),
        url(${NPSfontEB}) format('truetype');
        font-style: normal;
    }
    @font-face {
        font-family: "PretendardR";
        src: local("PretendardR"),
        url(${PretendardR}) format('woff');
        font-style: normal;
    }
`;

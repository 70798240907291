import {
  postSitterUsersApi,
  postFindPasswordApi,
  getSitterUsersMe,
  modifySitterInfos,
  postSitterInfosApi,
} from 'api/API';
import { makeAutoObservable, runInAction } from 'mobx';

class SitterStore {
  tab = 'basic';
  name = '';
  email = '';
  password = '';
  cellphone = '';
  country = {};
  birthDate = '';
  addressCode = '';
  address = '';
  addressDetails = '';
  sido = '';
  sigungu = '';
  sigunguCode = '';
  nationalityEtc = '';
  resetPasswordText = '';
  mySitterInfo = {};
  passwordResetResponseCode = '';

  constructor() {
    makeAutoObservable(this);
  }

  setTab = (param) => {
    this.tab = param;
  };

  setName = (param) => {
    this.name = param;
    console.log(param, 'param');
  };
  setEmail = (param) => {
    this.email = param;
  };
  setPassword = (param) => {
    this.password = param;
  };
  setCellphone = (param) => {
    this.cellphone = param;
  };
  setCountry = (param) => {
    this.country = param;
  };
  setBirthDate = (param) => {
    this.birthDate = param;
  };
  setAddressCode = (param) => {
    this.addressCode = param;
  };
  setAddress = (param) => {
    this.address = param;
  };
  setAddressDetails = (param) => {
    this.addressDetails = param;
  };
  setSido = (param) => {
    this.sido = param;
  };
  setSigungu = (param) => {
    this.sigungu = param;
  };
  setSigunguCode = (param) => {
    this.sigunguCode = param;
  };
  setNationalityEtc = (param) => {
    this.nationalityEtc = param;
  };
  setResetPasswordText = (param) => {
    this.resetPasswordText = param;
  };
  setPasswordResponseCode = (param) => {
    this.passwordResetResponseCode = param;
  };
  setMySitterInfo = (param) => {
    this.mySitterInfo = param;
  };

  postSitterUsers = async (data) => {
    await postSitterUsersApi(data)
      .then((res) => {
        console.log(res);
        if (res.data.responseCode === 'SUCCESS') {
          alert('성공적으로 요청되었습니다.');
          window.location.href = '/login';
          return;
        }
        alert('요청에 실패했습니다.');
      })
      .catch((error) => {
        console.log(error);
        alert('요청에 실패했습니다.');
      });
  };

  modifySitterInfos = async (data) => {
    await modifySitterInfos(data)
      .then((res) => {
        console.log(res);
        if (res.data.responseCode === 'SUCCESS') {
          alert('성공적으로 요청되었습니다.');
          window.location.href = '/';
          return;
        }
        alert('요청에 실패했습니다.');
      })
      .catch((error) => {
        console.log(error);
        alert('요청에 실패했습니다.');
      });
  };

  postFindPassword = async (data) => {
    await postFindPasswordApi(data)
      .then((res) => {
        console.log(res);
        this.setResetPasswordText(res.data.message);
        this.setPasswordResponseCode(res.data.responseCode);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getSitterUsersMe = async () => {
    await getSitterUsersMe()
      .then((res) => {
        console.log(res);
        if (res.data.responseCode === 'SUCCESS') {
          this.setMySitterInfo(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  postSitterInfos = async () => {
    await postSitterInfosApi()
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

const sitterStore = new SitterStore();

export default sitterStore;

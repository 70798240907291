import Navbar from 'components/templates/Navbar';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import bgMenuCommon from 'resources/img/bg_menu_common.png';
import bgMenuCommonMobile from 'resources/img/bg_menu_common_mobile.png';
import icnHome from 'resources/img/icn_home.png';
import icnArrowRight from 'resources/img/icn_arrow_right.png';
import Footer from 'components/templates/Footer';
import icnSearch from 'resources/img/icn_search.png';
import { useNavigate } from 'react-router-dom';
import arrowDownImg from 'resources/img/arrow_down.png';
import arrowRightImg from 'resources/img/icn_arrow_right_black.png';

const CustomerService = () => {
  const navigate = useNavigate();

  const dummy = [
    { id: 1, title: '[글로벌시터 가이드] 글로벌 시터 회원으로 가입하기' },
    { id: 2, title: '[글로벌시터 가이드] 글로벌시터는 어떤 서비스인가요?' },
    { id: 3, title: '[글로벌시터 가이드] 연결률 높이는 프로필 작성하기' },
    { id: 4, title: '[글로벌시터 가이드] 글로벌 시터 인증받기' },
  ];

  const handleItemClick = (item) => {
    navigate(`/customerService/${item.id}`);
  };

  return (
    <>
      <Navbar active={'customerService'} />
      <Wrap>
        <CurrentMenu image={bgMenuCommon} mobileImage={bgMenuCommonMobile}>
          <IndicatorContainer>
            <IndicatorFirst src={icnHome} />
            <IndicatorArrow src={icnArrowRight} />
            <IndicatorCurrent>고객센터</IndicatorCurrent>
          </IndicatorContainer>
          고객센터
        </CurrentMenu>
        <ContentContainer>
          <TopContainer>
            <InputContainer>
              <SearchInput placeholder="검색어를 입력하세요." /> <SearchImg src={icnSearch} />
            </InputContainer>
          </TopContainer>
          <ListContainer>
            {dummy.map((item, index) => (
              <ListItem onClick={() => handleItemClick(item)}>
                <Left>
                  <QMark>Q</QMark>
                  <ItemTitle>{item.title}</ItemTitle>
                </Left>
                <Arrow src={arrowRightImg} />
              </ListItem>
            ))}
          </ListContainer>
          <MoreButton>
            더보기 <ArrowDownImg src={arrowDownImg} />
          </MoreButton>
        </ContentContainer>
      </Wrap>
      <Footer />
    </>
  );
};

const Wrap = styled.div`
  padding: 106px 0 0 0;

  @media ${theme.laptop} {
    padding: 60px 0 0 0;
  }
`;

const CurrentMenu = styled.div`
  height: 320px;
  color: white;
  font-family: 'NPSfontEB';
  font-size: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  ${(props) =>
    css`
      background-image: url(${props.image});
    `}

  @media ${theme.desktop} {
    height: 240px;
    font-size: 36px;

    ${(props) =>
      css`
        background-image: url(${props.mobileImage});
      `}
  }
`;

const IndicatorContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 30px;
  display: flex;
`;

const IndicatorFirst = styled.img`
  height: 20px;
`;

const IndicatorArrow = styled.img``;

const IndicatorCurrent = styled.span`
  font-size: 13px;
  color: white;
  font-family: 'NanumSquareRoundEB';
  line-height: 20px;
`;

const ContentContainer = styled.div`
  width: 1280px;
  margin: 10px auto 10px;
  text-align: center;
  padding: 34px 40px 80px;
  justify-content: center;

  @media ${theme.desktop} {
    display: block;
    width: inherit;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 25px;
    margin-bottom: 80px;
    border-radius: 10px;
    padding: 24px 10px;
  }
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const InputContainer = styled.div`
  align-self: right;
  display: flex;
  align-items: center;
  border: 1px solid #d8dadd;
  border-radius: 28px;
  padding-left: 20px;
  padding-right: 20px;

  @media ${theme.desktop} {
    flex: 1;
  }
`;

const SearchInput = styled.input`
  width: 300px;
  font-family: 'NanumSquareRoundR';
  border: none;
  height: 56px;
  line-height: 56px;
  color: #adb2bb;
  font-size: 16px;
  outline: none;

  @media ${theme.desktop} {
    width: 100%;
    height: 48px;
    line-height: 48px;
    font-size: 14px;
  }
`;

const SearchImg = styled.img``;

const ListContainer = styled.div`
  margin-top: 30px;
  border-top: 3px solid #383b40;
`;

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 20px;
  border-bottom: 1px solid #e5e8ec;
  cursor: pointer;

  @media ${theme.desktop} {
    padding: 20px 4px;
  }
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const QMark = styled.span`
  font-family: 'NanumSquareRoundEB';
  font-size 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  color: #ffde00;
  background: #20c673;

  @media ${theme.desktop} {
    font-size 14px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
  }
`;

const Arrow = styled.img`
  width: 24px;
  height: 24px;

  @media ${theme.desktop} {
    width: 20px;
    height: 20px;
  }
`;

const ItemTitle = styled.div`
margin-left: 12px;
align-item: center;
font-family: 'NanumSquareRoundEB';
font-size 16px;
text-align: left;

@media ${theme.desktop} {
  font-size 14px;
  margin-left: 6px;
}
`;

const MoreButton = styled.button`
  border: 1px solid #383b40;
  border-radius: 24px;
  height: 40px;
  width: 160px;
  margin-top: 48px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  font-family: 'NanumSquareRoundR';
`;

const ArrowDownImg = styled.img`
  margin-left: 4px;
`;

export default CustomerService;

import Navbar from 'components/templates/Navbar';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import bgMenuCommon from 'resources/img/bg_menu_common.png';
import bgMenuCommonMobile from 'resources/img/bg_menu_common_mobile.png';
import imgParent from 'resources/img/img_parent.png';
import imgSitter from 'resources/img/img_sitter.png';
import logoGreen from 'resources/img/logo_green.png';
import icnHome from 'resources/img/icn_home.png';
import icnArrowRight from 'resources/img/icn_arrow_right.png';
import Footer from 'components/templates/Footer';
import { useNavigate } from 'react-router-dom';

const SelectUserType = () => {
  const navigate = useNavigate();

  return (
    <>
      <Navbar active={'selectUserType'} />
      <Wrap>
        <CurrentMenu image={bgMenuCommon} mobileImage={bgMenuCommonMobile}>
          <IndicatorContainer>
            <IndicatorFirst src={icnHome} />
            <IndicatorArrow src={icnArrowRight} />
            <IndicatorCurrent>회원가입</IndicatorCurrent>
          </IndicatorContainer>
          회원가입
        </CurrentMenu>
        <ContentContainer>
          <ItemContainer>
            <Icon src={imgParent} />
            <ItemRight>
              <Desc>
                아이들을 돌봐줄
                <br />
                맘시터가 필요해요
              </Desc>
              <ActionButtonParent onClick={() => navigate('/signSitter')}>
                부모회원 가입
              </ActionButtonParent>
            </ItemRight>
          </ItemContainer>
          <ItemContainer>
            <Icon src={imgSitter} />
            <ItemRight>
              <Desc>
                아이들을 돌봐줄
                <br />
                맘시터가 필요해요
              </Desc>
              <ActionButtonSitter onClick={() => navigate('/signSitter')}>
                시터회원 가입
              </ActionButtonSitter>
            </ItemRight>
          </ItemContainer>
        </ContentContainer>
        <LoginContainer>
          이미 맘시터 회원이신가요?<LoginTxt onClick={() => navigate('/login')}>로그인</LoginTxt>{' '}
        </LoginContainer>
      </Wrap>
      <Footer />
    </>
  );
};

const Wrap = styled.div`
  padding: 106px 0 0 0;

  @media ${theme.laptop} {
    padding: 60px 0 0 0;
  }
`;

const CurrentMenu = styled.div`
  height: 320px;
  color: white;
  font-family: 'NPSfontEB';
  font-size: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  ${(props) =>
    css`
      background-image: url(${props.image});
    `}

  @media ${theme.desktop} {
    height: 240px;
    font-size: 36px;

    ${(props) =>
      css`
        background-image: url(${props.mobileImage});
      `}
  }
`;

const IndicatorContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 30px;
  display: flex;
`;

const IndicatorFirst = styled.img`
  height: 20px;
`;

const IndicatorArrow = styled.img``;

const IndicatorCurrent = styled.span`
  font-size: 13px;
  color: white;
  font-family: 'NanumSquareRoundEB';
  line-height: 20px;
`;

const ContentContainer = styled.div`
  width: 1080px;
  margin: 50px auto 10px;
  text-align: center;
  padding: 84px 40px 20px;
  display: flex;
  justify-content: center;

  @media ${theme.desktop} {
    display: block;
    width: inherit;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 25px;
    margin-bottom: 0px;
    border-radius: 10px;
    padding: 24px 10px;
  }
`;

const ItemContainer = styled.div`
  border: 1px solid #d8dadd;
  width: 308px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 30px 20px;
  border-radius: 12px;
  margin-bottom: 20px;

  @media ${theme.desktop} {
    width: inherit;
    display: flex;
  }
`;

const ItemRight = styled.div``;

const Icon = styled.img`
  object-fit: contain;
`;

const Desc = styled.div`
  color: #383b40;
  font-size: 20px;
  font-family: 'NanumSquareRoundEB';
  margin-top: 20px;
`;

const ActionButtonParent = styled.button`
  background-color: #20c673;
  width: 100%;
  height: 56px;
  color: white;
  font-size: 16px;
  font-family: 'NanumSquareRoundEB';
  border-radius: 30px;
  margin-top: 30px;

  @media ${theme.desktop} {
    margin-top: 18px;
  }
`;

const ActionButtonSitter = styled.button`
  background-color: #c77dff;
  width: 100%;
  width: 100%;
  height: 56px;
  color: white;
  font-size: 16px;
  font-family: 'NanumSquareRoundEB';
  border-radius: 30px;
  margin-top: 30px;

  @media ${theme.desktop} {
    margin-top: 18px;
  }
`;

const LoginContainer = styled.div`
  font-family: 'NanumSquareRoundR';
  color: #383b40;
  font-size: 14px;
  text-align: center;
  margin-bottom: 100px;
`;
const LoginTxt = styled.span`
  color: #20c673;
  font-family: 'NanumSquareRoundEB';
  cursor: pointer;
`;

export default SelectUserType;

import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import Navbar from 'components/templates/Navbar';
import Footer from 'components/templates/Footer';
import DaumPostcode from 'react-daum-postcode';
import Checkbox from 'components/atoms/Checkbox';
import PopUp from 'components/atoms/PopUp';
import LabeledInput from 'components/atoms/LabeledInput';

const ResetSitterInfo = () => {
  const [inputs, setInputs] = useState({
    name: '',
    nationality: '',
    password: '',
    passwordCheck: '',
    birth: '',
    contact: '',
    email: '',
    address: '',
    zipcode: '',
    detailAddress: '',
    certificationCode: '',
  });

  const {
    name,
    nationality,
    password,
    passwordCheck,
    birth,
    contact,
    email,
    address,
    zipcode,
    detailAddress,
    certificationCode,
  } = inputs;

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const [showPostCode, setShowPostCode] = useState(false);

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    setInputs({ ...inputs, address: fullAddress, zipcode: data.zonecode });
    setShowPostCode(false);
  };

  const postCodeStyle = {
    display: 'block',
    position: 'relative',
    top: '17px',
    left: '0',
    width: '700px',
    height: '400px',
    zIndex: 100,
    border: '2px solid #bcbcbc',
    borderRadius: '10px',
    padding: '5px',
  };

  const [popupVisible, setPopupVisible] = useState(false);

  const handleOpenPopup = () => {
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  const [privacyCheck, setPrivacyCheck] = useState(false);

  const handleChangePrivacyCheck = () => {
    setPrivacyCheck(!privacyCheck);
  };

  const [showCertification, setShowCertification] = useState(false);
  const handleCertification = () => {
    setShowCertification(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, nationality, birth, zipcode, address, detailAddress, privacyCheck);
  };

  return (
    <>
      <Navbar />
      <Wrap>
        <Title>시터 정보 변경하기</Title>
        <Form onSubmit={handleSubmit}>
          <LabeledInput
            label="이름"
            name="name"
            value={name}
            placeholder="이름을 입력해주세요"
            onChange={onChange}
          />
          <LabeledInput
            label="이메일"
            name="email"
            value={email}
            placeholder="이메일을 입력해주세요"
            onChange={onChange}
          />
          <LabeledInput
            label="비밀번호"
            name="password"
            value={password}
            placeholder="비밀번호를 입력해주세요"
            onChange={onChange}
          />
          <LabeledInput
            label="비밀번호 확인"
            name="passwordCheck"
            value={passwordCheck}
            placeholder="비밀번호를 한 번 더 입력해주세요"
            onChange={onChange}
          />
          <LabelInput>
            <Label>연락처</Label>
            <ContactInputWrap>
              <Input
                name="contact"
                value={contact}
                placeholder="연락처를 입력해주세요"
                onChange={onChange}
              />
              <ContactInputButton type="button" onClick={() => handleCertification()}>
                번호 인증
              </ContactInputButton>
            </ContactInputWrap>
            {showCertification && (
              <ContactInputWrap>
                <Input
                  name="certificationCode"
                  value={certificationCode}
                  placeholder="인증번호를 입력해주세요"
                  onChange={onChange}
                />
                <ContactInputButton type="button">인증번호 확인</ContactInputButton>
              </ContactInputWrap>
            )}
          </LabelInput>
          <LabelInput>
            <Label>국적</Label>
            <NationalButton onClick={handleOpenPopup} type="button">
              국적을 입력해주세요
            </NationalButton>
            <Input
              name="nationality"
              value={nationality}
              placeholder="선택지에 없는 경우 직접 입력해주세요"
              onChange={onChange}
            />
          </LabelInput>
          <LabeledInput
            label="생년월일"
            name="birth"
            value={birth}
            placeholder="생년월일을 입력해주세요"
            onChange={onChange}
          />
          <LabelInput>
            <Label>주소</Label>
            <ZipCode>
              <ZipCodeInput
                name="zipCode"
                placeholder="우편번호"
                defaultValue={inputs.zipcode}
                disabled
              />
              <ZipCodeButton type="button" onClick={() => setShowPostCode(!showPostCode)}>
                우편번호
              </ZipCodeButton>
            </ZipCode>
            <AddressInput placeholder="주소" defaultValue={inputs.address} disabled />
            <AddressInput
              name="detailAddress"
              value={detailAddress}
              placeholder="상세주소를 입력해주세요"
              onChange={onChange}
            />
            {showPostCode ? (
              <div>
                <DaumPostcode onComplete={handleComplete} style={postCodeStyle} />
              </div>
            ) : (
              <></>
            )}
          </LabelInput>
          <PrivacyNotice>
            &lt;개인정보 처리 방침&gt; 수강생 출석 확인과 서비스 품질 개선 목적으로 ZOOM 강의 영상을
            실시간녹화하고 있습니다. 해당 녹화본은 수집 목적 외 용도로 사용되지 않으며, 보유기간이
            지난 후 즉시 폐기됩니다.
          </PrivacyNotice>
          <Checkbox
            type="checkbox"
            name="privacyCheck"
            handleClick={handleChangePrivacyCheck}
            isChecked={privacyCheck}
          >
            개인정보 수집/이용 동의
          </Checkbox>
          <SubmitButton type="submit">다음</SubmitButton>
        </Form>
      </Wrap>
      <Footer />
      <PopUp visible={popupVisible} onClose={handleClosePopup}>
        <PopUpTitle>국적 선택</PopUpTitle>
        <PopUpTab>
          {/* {counrtyStore.countryData.map((item) => (
            <PopUpLanguageItemWrap
              onClick={() =>
                handleCountryTab({ countryIsoCode: item.countryIsoCode, name: item.name })
              }
              tab={countryInfo?.countryIsoCode === item.countryIsoCode}
              key={item.countryIsoCode}
            >
              <PopUpLanguageItem>
                <Flag>
                  <img src={item.imageUrl} alt="flag" />
                </Flag>
                <FlagText>{item.name}</FlagText>
              </PopUpLanguageItem>
            </PopUpLanguageItemWrap>
          ))} */}
        </PopUpTab>
        <PopUpButton onClick={handleClosePopup}>선택완료</PopUpButton>
      </PopUp>
    </>
  );
};
const Wrap = styled.div`
  padding: 106px 0 0 0;

  @media ${theme.laptop} {
    padding: 60px 0 0 0;
  }
`;
const Title = styled.p`
  color: ${theme.colors.deepGreen};
  font-family: 'GmarketSansMedium';
  font-size: 50px;
  letter-spacing: -1.25px;
  text-align: center;
  margin: 72px 0 53px 0;
`;

const Form = styled.form`
  width: 700px;
  margin: 80px auto 0;
`;

const LabelInput = styled.div`
  margin: 0 0 100px 0;
  position: relative;
`;

const Label = styled.p`
  font-size: 30px;
  line-height: 30px;
  font-family: 'GmarketSansMedium';
  letter-spacing: -1.5px;
  color: #2d2d2d;
  margin: 0 0 17px 7px;
`;

const Input = styled.input`
  border: 2px solid ${theme.colors.gray};
  width: 100%;
  height: 83px;
  border-radius: 10px;
  font-size: 25px;
  font-family: 'GmarketSansMedium';
  padding: 0 26px;

  &::placeholder {
    color: ${theme.colors.gray};
  }

  &:disabled {
    background-color: #f4f4f4;
    color: #bcbcbc;
  }
`;

const ContactInputWrap = styled.div`
  display: flex;

  & + & {
    margin-top: 10px;
  }
`;

const ZipCode = styled.div`
  display: flex;
`;

const ZipCodeInput = styled(Input)``;

const ZipCodeButton = styled.button`
  height: 83px;
  border: 2px solid ${theme.colors.gray};
  border-radius: 10px;
  font-size: 21px;
  font-family: 'GmarketSansMedium';
  color: #848484;
  background-color: white;
  width: 200px;
  margin: 0 0 0 15px;
`;

const ContactInputButton = styled(ZipCodeButton)``;

const AddressInput = styled(Input)`
  margin: 17px 0 0 0;
`;

const PrivacyNotice = styled.p`
  font-family: 'GmarketSansLight';
  font-size: 22px;
  line-height: 46px;
  letter-spacing: -1.1px;
  color: #353535;
`;

const SubmitButton = styled.button`
  border: 4px solid ${theme.colors.primary};
  width: 200px;
  height: 84px;
  border-radius: 50px;
  background-color: white;
  margin: 115px auto 93px;
  font-size: 30px;
  color: ${theme.colors.primary};
  letter-spacing: -0.75px;
  font-family: 'GmarketSansMedium';
  display: block;
`;

const NationalButton = styled.button`
  border: 2px solid ${theme.colors.gray};
  width: 100%;
  height: 83px;
  border-radius: 10px;
  font-size: 25px;
  font-family: 'GmarketSansMedium';
  padding: 23px 26px;
  color: ${theme.colors.gray};
  margin: 0 0 15px 0;
  background-color: transparent;
  text-align: left;
`;

const PopUpTitle = styled.p`
  text-align: center;
  font-family: 'GmarketSansMedium';
  font-size: 25px;
`;

const PopUpTab = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 70px 0 0 0;
`;

const PopUpLanguageItemWrap = styled.div`
  width: 92px;
  height: 108px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.tab === true &&
    css`
      background-color: rgba(7, 0, 133, 0.2);
      border-radius: 5px;
    `}
`;

const PopUpLanguageItem = styled.div`
  text-align: center;
`;

const Flag = styled.div`
  width: 30px;
  height: 20px;
  background-color: #eee;
  margin: 0 auto 25px;

  & > img {
    width: 30px;
    height: 20px;
    object-fit: cover;
  }
`;

const PopUpButton = styled.button`
  width: 150px;
  border-radius: 30px;
  height: 60px;
  border: 2px solid ${theme.colors.primary};
  margin: 20px auto;
  color: ${theme.colors.primary};
  background-color: white;
  font-size: 20px;
  display: block;
  font-family: 'GmarketSansMedium';
`;

export default ResetSitterInfo;

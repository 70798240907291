import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import Navbar from 'components/templates/Navbar';
import Footer from 'components/templates/Footer';
import PopUp from 'components/atoms/PopUp';
import LabeledInput from 'components/atoms/LabeledInput';
import { observer } from 'mobx-react';
import stores from 'stores';

const ResetPassword = observer(() => {
  const { sitterStore } = stores;
  const [email, setEmail] = useState('');

  const onChange = (e) => {
    setEmail(e.target.value);
  };

  const [popupVisible, setPopupVisible] = useState(false);

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  const handleFindPassword = () => {
    sitterStore.postFindPassword({ email: email });
    setPopupVisible(true);
  };

  return (
    <>
      <Navbar />
      <Wrap>
        <Title>로그인</Title>
        <PasswordBox>
          <PasswordBoxTitle>비밀번호 찾기</PasswordBoxTitle>
          <InputWrap>
            <LabeledInput
              label="이메일"
              name="email"
              value={email}
              placeholder="이메일을 입력해주세요"
              onChange={onChange}
              size="small"
            />
          </InputWrap>
          <Button onClick={handleFindPassword}>비밀번호 찾기</Button>
        </PasswordBox>
      </Wrap>
      <Footer />
      <PopUp visible={popupVisible} onClose={handleClosePopup}>
        <PopUpText>
          {sitterStore.passwordResetResponseCode === 'FAILURE' ? (
            sitterStore.resetPasswordText
          ) : (
            <span>
              해당 이메일로 발송된 링크에서
              <br />
              비밀번호를 변경해주세요
            </span>
          )}
        </PopUpText>
        <PopUpButton onClick={handleClosePopup}>확인</PopUpButton>
      </PopUp>
    </>
  );
});

const Wrap = styled.div`
  padding: 106px 0 200px 0;
`;

const Title = styled.p`
  color: ${theme.colors.deepGreen};
  font-family: 'GmarketSansMedium';
  font-size: 50px;
  letter-spacing: -1.25px;
  text-align: center;
  margin: 72px 0 53px 0;
`;

const PasswordBox = styled.div`
  border: 2px solid ${theme.colors.gray};
  border-radius: 10px;
  width: 700px;
  height: 400px;
  margin: 0 auto;
`;

const PasswordBoxTitle = styled.p`
  font-size: 35px;
  font-family: 'GmarketSansMedium';
  color: #2d2d2d;
  text-align: center;
  margin: 40px 0 60px; ;
`;

const InputWrap = styled.div`
  margin: 0 auto;
  position: relative;
  width: 400px;
`;

const Button = styled.button`
  height: 60px;
  border: 4px solid ${theme.colors.primary};
  border-radius: 30px;
  font-size: 18px;
  font-family: 'GmarketSansMedium';
  color: ${theme.colors.primary};
  background-color: white;
  width: 200px;
  margin: 30px auto 0;
  display: block;
`;

const PopUpText = styled.p`
  text-align: center;
  font-size: 30px;
  font-family: 'GmarketSansMedium';
  margin: 0 0 130px 0;
`;

const PopUpButton = styled.button`
  width: 150px;
  border-radius: 30px;
  height: 60px;
  border: 2px solid ${theme.colors.primary};
  margin: 20px auto;
  color: ${theme.colors.primary};
  background-color: white;
  font-size: 20px;
  display: block;
  font-family: 'GmarketSansMedium';
`;

export default ResetPassword;

import Navbar from 'components/templates/Navbar';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import bgMenuCommon from 'resources/img/bg_menu_common.png';
import bgMenuCommonMobile from 'resources/img/bg_menu_common_mobile.png';
import icnHome from 'resources/img/icn_home.png';
import icnArrowRight from 'resources/img/icn_arrow_right.png';
import icnFilter from 'resources/img/icn_filter.png';
import icnRegisterSitter from 'resources/img/icn_register_sitter.png';
import arrowDown from 'resources/img/arrow_down.png';
import arrowDownBlack from 'resources/img/arrow_down_black.png';
import icnBaby1 from 'resources/img/icn_baby_1.png';
import icnBaby2 from 'resources/img/icn_baby_2.png';
import icnBaby3 from 'resources/img/icn_baby_3.png';
import icnBaby4 from 'resources/img/icn_baby_4.png';
import icnHeartOff from 'resources/img/icn_heart_off.png';
import icnHeartOn from 'resources/img/icn_heart_on.png';
import arrowDownImg from 'resources/img/arrow_down.png';
import icnReset from 'resources/img/icn_reset.png';
import Footer from 'components/templates/Footer';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PopUp from 'components/atoms/PopUp';
import { observer } from 'mobx-react';
import stores from 'stores';
import { toJS } from 'mobx';
import { Slider } from '@mui/material';

const SearchParent = observer(() => {
  const { regionStore } = stores;
  const navigate = useNavigate();

  const [regionPopupOpened, setRegionPopupOpened] = useState(false);
  const [filterDetailOpened, setFilterDetailOpened] = useState(false);
  const dummy = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
    { id: 7 },
    { id: 8 },
  ];

  const [topRegion, setTopRegion] = useState();
  const [secondRegion, setSecondRegion] = useState();
  const [thirdRegion, setThirdRegion] = useState();

  const [pay, setPay] = useState([20, 37]);

  const handleChange = (event, newValue) => {
    setPay(newValue);
  };

  useEffect(() => {
    regionStore.getRegions();
  }, [regionStore]);

  const handleClickTopRegion = (item) => {
    setTopRegion(item);
    regionStore.getSecondRegions(item.id);
  };

  const handleClickSecondRegion = (item) => {
    setSecondRegion(item);
    regionStore.getThirdRegions(item.id);
  };

  const handleClickThirdRegion = (item) => {
    setThirdRegion(item);
  };

  const regionStoreData = toJS(regionStore.regionData);
  const regionSecondData = toJS(regionStore.regionSecondData);
  const regionThirdData = toJS(regionStore.regionThirdData);

  return (
    <>
      <Navbar active={'searchParent'} />
      <Wrap>
        <CurrentMenu image={bgMenuCommon} mobileImage={bgMenuCommonMobile}>
          <IndicatorContainer>
            <IndicatorFirst src={icnHome} />
            <IndicatorArrow src={icnArrowRight} />
            <IndicatorCurrent>부모찾기</IndicatorCurrent>
          </IndicatorContainer>
          부모찾기
        </CurrentMenu>
        <ContentContainer>
          <FilterRegionContainer>
            <RegionFilter onClick={() => setRegionPopupOpened(true)}>
              <RegionText>전체 지역</RegionText>
              <ArrowDownButton src={arrowDownBlack} />
            </RegionFilter>
          </FilterRegionContainer>
          <FilterContainer>
            <FilterLeft>
              <FilterButton onClick={() => setFilterDetailOpened(true)}>
                <FilterImg src={icnFilter} /> <span>필터</span>
              </FilterButton>
              <FilterButton>
                급여선택
                <FilterButtonDown src={arrowDown} />
              </FilterButton>
              <FilterButton>
                희망일정
                <FilterButtonDown src={arrowDown} />
              </FilterButton>
              <FilterButton>
                희망나이
                <FilterButtonDown src={arrowDown} />
              </FilterButton>
              <FilterButton>
                돌봄서비스
                <FilterButtonDown src={arrowDown} />
              </FilterButton>
            </FilterLeft>
            <RegisterSitterButton>
              <SitterImg onClick={() => navigate('/signSitter', {})} src={icnRegisterSitter} />
              시터등록
            </RegisterSitterButton>
          </FilterContainer>
          <CardContainer>
            <ListSummary>
              <Summary>
                <SummaryTxt>
                  전체 일자리 <SummaryBold>1,293</SummaryBold>개
                </SummaryTxt>
                <OrderContainer>
                  프로필 업데이트순
                  <FilterButtonDown src={arrowDown} />
                </OrderContainer>
              </Summary>
            </ListSummary>
            <CardListContainer>
              {dummy.map((item, index) => (
                <Card>
                  <CardTop>
                    <IcnBaby src={icnBaby1} />
                    <HeartButton src={icnHeartOff} />
                  </CardTop>
                  <SmallTxt>신생아 1명</SmallTxt>
                  <CardTitle>용산구 이촌동 입주도우미 구합니다.</CardTitle>
                  <SmallTxt>12.01 시작</SmallTxt>
                  <span>&#183;</span>
                  <SmallTxt>월/화/수/목/금</SmallTxt>
                  <span>&#183;</span>
                  <SmallTxt>단기</SmallTxt>
                  <CardPrice>희망시급 15,000원</CardPrice>
                  <CardButton>자세히보기</CardButton>
                </Card>
              ))}
            </CardListContainer>
          </CardContainer>
          <MoreButton>
            더보기 <ArrowDownImg src={arrowDownImg} />
          </MoreButton>
        </ContentContainer>
      </Wrap>
      <PopUp
        nopadding={'true'}
        title={'돌봄지역 선택'}
        visible={regionPopupOpened}
        onClose={() => setRegionPopupOpened(false)}
      >
        <RegionContainer>
          <RegionColumn>
            {regionStoreData.map((item, index) => (
              <RegionTxt
                selected={item.id === topRegion?.id}
                onClick={() => handleClickTopRegion(item)}
              >
                {item.sido}
              </RegionTxt>
            ))}
          </RegionColumn>
          <RegionColumn>
            {regionSecondData.map((item, index) => (
              <RegionTxt
                selected={item.id === secondRegion?.id}
                onClick={() => handleClickSecondRegion(item)}
              >
                {item.sigungu}
              </RegionTxt>
            ))}
          </RegionColumn>
          <RegionColumn>
            {regionThirdData.map((item, index) => (
              <RegionTxt
                selected={item.id === thirdRegion?.id}
                onClick={() => handleClickThirdRegion(item)}
              >
                {item.sigungu}
              </RegionTxt>
            ))}
          </RegionColumn>
        </RegionContainer>
        <ButtonContainer>
          <ResetButton>
            <ResetImg src={icnReset} />
            초기화
          </ResetButton>
          <ApplyButton>적용하기</ApplyButton>
        </ButtonContainer>
      </PopUp>
      <PopUp
        nopadding={'true'}
        title={'필터 상세 검색'}
        visible={filterDetailOpened}
        onClose={() => setFilterDetailOpened(false)}
      >
        <FilterPopupContainer>
          <FilterSection>
            <FilterTitle>급여선택</FilterTitle>
            <FilterSubTitle>급여범위</FilterSubTitle>
            <CustomSlider
              getAriaLabel={() => '급여 범위'}
              value={pay}
              onChange={handleChange}
              valueLabelDisplay="auto"
              getAriaValueText={(value) => `${value}원`}
            />
          </FilterSection>
          <FilterSection>
            <FilterTitle>희망일정</FilterTitle>
            <FilterSubTitle>요일</FilterSubTitle>
            <FilterContainerDaysInPopup>
              <FilterButtonInPopup>월요일</FilterButtonInPopup>
              <FilterButtonInPopup>화요일</FilterButtonInPopup>
              <FilterButtonInPopup>수요일</FilterButtonInPopup>
              <FilterButtonInPopup>목요일</FilterButtonInPopup>
              <FilterButtonInPopup>금요일</FilterButtonInPopup>
              <FilterButtonInPopup>토요일</FilterButtonInPopup>
              <FilterButtonInPopup>일요일</FilterButtonInPopup>
            </FilterContainerDaysInPopup>
            <FilterSubTitle>시간대</FilterSubTitle>
            <FilterContainerHourInPopup>
              <FilterButtonInPopup>07~12시</FilterButtonInPopup>
              <FilterButtonInPopup>12~18시</FilterButtonInPopup>
              <FilterButtonInPopup>18~22시</FilterButtonInPopup>
            </FilterContainerHourInPopup>
          </FilterSection>
          <FilterSection>
            <FilterTitle>희망나이</FilterTitle>
            <FilterSubTitle>아이 나이대</FilterSubTitle>
            <FilterContainerBabyTypeInPopup>
              <FilterButtonBabyTypeInPopup>
                <BabyImage src={icnBaby1} />
                신생아
              </FilterButtonBabyTypeInPopup>
              <FilterButtonBabyTypeInPopup>
                <BabyImage src={icnBaby2} />
                영아
              </FilterButtonBabyTypeInPopup>
              <FilterButtonBabyTypeInPopup>
                <BabyImage src={icnBaby3} />
                유아
              </FilterButtonBabyTypeInPopup>
              <FilterButtonBabyTypeInPopup>
                <BabyImage src={icnBaby4} />
                초등학생
              </FilterButtonBabyTypeInPopup>
            </FilterContainerBabyTypeInPopup>
            <FilterSubTitle>시간대</FilterSubTitle>
            <FilterContainerHourInPopup>
              <FilterButtonInPopup>무관</FilterButtonInPopup>
              <FilterButtonInPopup>1명</FilterButtonInPopup>
              <FilterButtonInPopup>2명</FilterButtonInPopup>
            </FilterContainerHourInPopup>
          </FilterSection>
          <FilterSection>
            <FilterTitle>돌봄서비스</FilterTitle>
            <FilterSubTitle>종류</FilterSubTitle>
            <FilterContainerTypeInPopup>
              <FilterButtonInPopup>실내놀이</FilterButtonInPopup>
              <FilterButtonInPopup>등하원돕기</FilterButtonInPopup>
              <FilterButtonInPopup>책읽기</FilterButtonInPopup>
              <FilterButtonInPopup>야외활동</FilterButtonInPopup>
              <FilterButtonInPopup>한글놀이</FilterButtonInPopup>
              <FilterButtonInPopup>영어놀이</FilterButtonInPopup>
              <FilterButtonInPopup>학습지도</FilterButtonInPopup>
              <FilterButtonInPopup>체육놀이</FilterButtonInPopup>
              <FilterButtonInPopup>간단청소</FilterButtonInPopup>
              <FilterButtonInPopup>밥챙겨주기</FilterButtonInPopup>
              <FilterButtonInPopup>간단설거지</FilterButtonInPopup>
              <FilterButtonInPopup>장기입주</FilterButtonInPopup>
            </FilterContainerTypeInPopup>
          </FilterSection>
        </FilterPopupContainer>
        <ButtonContainer>
          <ResetButton>
            <ResetImg src={icnReset} />
            초기화
          </ResetButton>
          <ApplyButton>적용하기</ApplyButton>
        </ButtonContainer>
      </PopUp>
      <Footer />
    </>
  );
});

const Wrap = styled.div`
  padding: 106px 0 0 0;

  @media ${theme.laptop} {
    padding: 60px 0 0 0;
  }
`;

const CurrentMenu = styled.div`
  height: 320px;
  color: white;
  font-family: 'NPSfontEB';
  font-size: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  ${(props) =>
    css`
      background-image: url(${props.image});
    `}

  @media ${theme.desktop} {
    height: 240px;
    font-size: 36px;

    ${(props) =>
      css`
        background-image: url(${props.mobileImage});
      `}
  }
`;

const IndicatorContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 30px;
  display: flex;
`;

const IndicatorFirst = styled.img`
  height: 20px;
`;

const IndicatorArrow = styled.img``;

const IndicatorCurrent = styled.span`
  font-size: 13px;
  color: white;
  font-family: 'NanumSquareRoundEB';
  line-height: 20px;
`;

const ContentContainer = styled.div`
  width: 1280px;
  margin: 10px auto 100px;
  text-align: center;
  padding: 34px 40px 0px;
  justify-content: center;

  @media ${theme.desktop} {
    display: block;
    width: inherit;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 25px;
    margin-bottom: 0px;
    border-radius: 10px;
    padding: 24px 10px;
  }
`;

const FilterRegionContainer = styled.div`
  text-align: left;
`;

const RegionFilter = styled.button`
  background: white;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const RegionText = styled.span`
  color: #383b40;
  font-family: 'NanumSquareRoundEB';
  font-size: 18px;
`;

const ArrowDownButton = styled.img`
  margin-left: 4px;
`;

const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 18px;

  @media ${theme.tablet} {
    display: block;
    overflow-x: scroll;
    overflow-y: hidden;
  }
`;

const FilterLeft = styled.div`
  display: flex;
`;

const FilterImg = styled.img`
  margin-right: 4px;
`;

const SitterImg = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;

const FilterButton = styled.button`
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'NanumSquareRoundR';
  font-size: 14px;
  padding-left: 18px;
  padding-right: 18px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #d8dadd;
  border-radius: 20px;
  margin-right: 12px;

  @media ${theme.tablet} {
    font-size: 13px;
    padding-left: 6px;
    padding-right: 6px;
    height: 36px;
    line-height: 36px;
    min-width: 95px;
  }
`;

const FilterButtonDown = styled.img`
  margin-left: 4px;
`;

const CardContainer = styled.div`
  margin-top: 20px;
  border-top: 1px solid #e5e8ec;
`;

const ListSummary = styled.div`
  margin-top: 28px;
  font-family: 'NanumSquareRoundR';
  font-size: 14px;
`;

const Summary = styled.div`
  display: flex;
  color: #383b40;
  justify-content: space-between;
`;

const SummaryTxt = styled.div`
  font-family: 'NanumSquareRoundR';
  font-size: 14px;
  display: flex;
  color: #383b40;
  justify-content: space-between;
`;

const SummaryBold = styled.div`
  font-family: 'NanumSquareRoundEB';
  font-size: 14px;
  margin-left: 8px;
`;

const OrderContainer = styled.div`
  font-family: 'NanumSquareRoundR';
  font-size: 14px;
  display: flex;
  align-items: center;
`;

const RegisterSitterButton = styled.button`
  padding-left: 22px;
  padding-right: 22px;
  border-radius: 20px;
  font-family: 'NanumSquareRoundEB';
  color: white;
  height: 40px;
  background: ${theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${theme.tablet} {
    margin-top: 12px;
    width: 100%;
  }
`;

const CardListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 25px;

  @media ${theme.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media ${theme.mobile} {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  margin-top: 20px;
  border-radius: 12px;
  padding: 18px;
  text-align: left;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  -ms-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  -o-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`;

const CardTop = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
`;

const IcnBaby = styled.img``;

const HeartButton = styled.img`
  width: 24px;
  height: 24px;
`;

const SmallTxt = styled.span`
  color: #383b40;
  font-size: 14px;
  font-family: 'NanumSquareRoundR';
`;

const CardTitle = styled.div`
  font-family: 'NanumSquareRoundEB';
  color: #383b40;
  font-size: 22px;
  margin-top: 6px;
  margin-bottom: 12px;
`;

const CardPrice = styled.div`
  font-family: 'NanumSquareRoundEB';
  color: #20c673;
  font-size: 16px;
  margin-top: 6px;
`;

const CardButton = styled.button`
  margin-top: 18px;
  width: 100%;
  background: #20c673;
  height: 48px;
  border-radius: 24px;
  font-family: 'NanumSquareRoundEB';
  color: white;
  font-size: 16px;
`;

const MoreButton = styled.button`
  border: 1px solid #383b40;
  border-radius: 24px;
  height: 40px;
  width: 160px;
  margin-top: 48px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  font-family: 'NanumSquareRoundR';
`;

const ArrowDownImg = styled.img`
  margin-left: 4px;
`;

const RegionContainer = styled.div`
  display: flex;
`;

const RegionColumn = styled.div`
  flex: 1;
  display: block;
  border-left: 1px solid #e5e8ec;
  border-right: 1px solid #e5e8ec;
`;

const RegionTxt = styled.div`
  color: #383b40;
  height: 56px;
  cursor: pointer;
  text-align: center;
  line-height: 56px;
  font-size: 16px;
  font-family: 'NanumSquareRoundR';

  ${(props) =>
    props.selected &&
    css`
      font-family: 'NanumSquareRoundEB';
      background: rgba(32, 198, 115, 0.1);
      color: #20c673;
    `}
`;

const ButtonContainer = styled.div`
  border-top: 1px solid #e5e8ec;
  padding: 24px;
  display: flex;

  @media ${theme.tablet} {
    padding: 0px;
  }
`;

const ResetImg = styled.img`
  margin-right: 8px;
`;

const ResetButton = styled.button`
  flex: 1;
  margin-right: 8px;
  height: 64px;
  border-radius: 32px;
  background: #e5e8ec;
  color: #747983;
  font-size: 18px;
  font-family: 'NanumSquareRoundR';
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${theme.tablet} {
    margin: 0;
    font-size: 16px;
    height: 56px;
    border-radius: 0px;
  }
`;

const ApplyButton = styled.button`
  flex: 1;
  margin-left: 8px;
  height: 64px;
  border-radius: 32px;
  background: #20c673;
  color: white;
  font-size: 18px;
  font-family: 'NanumSquareRoundEB';

  @media ${theme.tablet} {
    margin: 0;
    font-size: 16px;
    height: 56px;
    border-radius: 0px;
  }
`;

const FilterPopupContainer = styled.div`
  padding: 18px;
`;

const FilterSection = styled.div`
  border-top: 1px solid #e5e8ec;
  padding-top: 30px;
  padding-bottom: 30px;
  &:first-child {
    border: none;
  }
`;

const FilterTitle = styled.div`
  color: #383b40;
  font-size: 18px;
  font-family: 'NanumSquareRoundEB';
`;

const FilterSubTitle = styled.div`
  color: #383b40;
  margin-top: 18px;
  font-size: 16px;
  font-family: 'NanumSquareRoundEB';
`;

const FilterButtonInPopup = styled.button`
  font-size: 16px;
  font-family: 'NanumSquareRoundR';
  color: #383b40;
  height: 56px;
  border: 1px solid #d8dadd;
  border-radius: 26px;
  background: white;
  flex: 1;
  margin-top: 8px;

  @media ${theme.tablet} {
    height: 36px;
    font-size: 14px;
  }
`;

const FilterButtonBabyTypeInPopup = styled(FilterButtonInPopup)`
  height: 128px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FilterContainerInPopup = styled.div`
  display: grid;
  justify-content: space-between;
  background: white;
  margin-top: 12px;
`;

const FilterContainerBabyTypeInPopup = styled(FilterContainerInPopup)`
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 25px;

  @media ${theme.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8px;
  }
`;

const BabyImage = styled.img`
  width: 56px;
  height: 56px;
  margin-bottom: 12px;
`;

const FilterContainerDaysInPopup = styled(FilterContainerInPopup)`
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 25px;

  @media ${theme.tablet} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 8px;
  }
`;

const FilterContainerHourInPopup = styled(FilterContainerInPopup)`
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 25px;
`;

const FilterContainerTypeInPopup = styled(FilterContainerInPopup)`
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 25px;

  @media ${theme.mobile} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 8px;
  }
`;

const CustomSlider = styled(Slider)({
  color: '#20C673',
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#20C673',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
  '& .MuiSlider-thumb': {
    color: '#20C673',
    background: 'white',
    border: '2px solid #20C673',
  },
  '& .MuiSlider-track': {
    color: '#20C673',
  },
  '& .MuiSlider-rail': {
    color: '#acc4e4',
  },
  '& .MuiSlider-active': {
    color: 'green',
  },
});

export default SearchParent;

import Main from 'components/pages/main/Main';
import SitterSearching from 'components/pages/sitterSearching/SitterSearching';
import OnlineEducation from 'components/pages/onlineEducation/OnlineEducation';
import SignEducation from 'components/pages/signEducation/SignEducation';
import ResetPassword from 'components/pages/sign/ResetPassword';
import SignSitter from 'components/pages/signEducation/SignSitter';
import SignSitterAdd from 'components/pages/signEducation/SignSitterAdd';
import ApplicationSitter from 'components/pages/signEducation/ApplicationSitter';
import ResetSitterInfo from 'components/pages/resetSitterInfo/ResetSitterInfo';
import ResetSitterInfoAdd from 'components/pages/resetSitterInfo/ResetSitterInfoAdd';
import Introduce from 'components/pages/Introduce';
import SelectUserType from 'components/pages/SelectUserType';
import Login from 'components/pages/Login';
import CustomerService from 'components/pages/CustomerService';
import CustomerServiceDetail from 'components/pages/CustomerServiceDetail';
import SearchParent from 'components/pages/SearchParent';

export const routes = [
  { path: '/', element: <Main /> },
  { path: '/introduce', element: <Introduce /> },
  { path: '/selectUserType', element: <SelectUserType /> },
  { path: '/customerService', element: <CustomerService /> },
  { path: '/customerService/:id', element: <CustomerServiceDetail /> },
  { path: '/searchParent', element: <SearchParent /> },
  { path: '/sitterSearching', element: <SitterSearching /> },
  { path: '/onlineEdu', element: <OnlineEducation /> },
  { path: '/signEdu', element: <SignEducation /> },
  { path: '/login', element: <Login /> },
  { path: '/resetPassword', element: <ResetPassword /> },
  { path: '/signSitter', element: <SignSitter /> },
  { path: '/signSitter/add', element: <SignSitterAdd /> },
  { path: '/applicationSitter', element: <ApplicationSitter /> },
  { path: '/changeSitterInfo', element: <ResetSitterInfo /> },
  { path: '/changeSitterInfo/add', element: <ResetSitterInfoAdd /> },
];

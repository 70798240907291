import Navbar from 'components/templates/Navbar';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import bgMenuCommon from 'resources/img/bg_menu_common.png';
import bgMenuCommonMobile from 'resources/img/bg_menu_common_mobile.png';
import icnHome from 'resources/img/icn_home.png';
import icnArrowRight from 'resources/img/icn_arrow_right.png';
import Footer from 'components/templates/Footer';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();

  return (
    <>
      <Navbar active={'selectUserType'} />
      <Wrap>
        <CurrentMenu image={bgMenuCommon} mobileImage={bgMenuCommonMobile}>
          <IndicatorContainer>
            <IndicatorFirst src={icnHome} />
            <IndicatorArrow src={icnArrowRight} />
            <IndicatorCurrent>로그인</IndicatorCurrent>
          </IndicatorContainer>
          로그인
        </CurrentMenu>
        <ContentContainer>
          <ItemContainer>
            <Input placeholder="이메일 주소를 입력하세요." type="email" />
            <Input placeholder="비밀번호를 입력하세요." type="password" />
            <ActionButtonLogin>로그인</ActionButtonLogin>
          </ItemContainer>
        </ContentContainer>
        <LoginContainer>
          아직 글로벌 시터 회원이 아니신가요?{' '}
          <LoginTxt onClick={() => navigate('/selectUserType')}>회원가입</LoginTxt>{' '}
        </LoginContainer>
      </Wrap>
      <Footer />
    </>
  );
};

const Wrap = styled.div`
  padding: 106px 0 0 0;

  @media ${theme.laptop} {
    padding: 60px 0 0 0;
  }
`;

const CurrentMenu = styled.div`
  height: 320px;
  color: white;
  font-family: 'NPSfontEB';
  font-size: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  ${(props) =>
    css`
      background-image: url(${props.image});
    `}

  @media ${theme.desktop} {
    height: 240px;
    font-size: 36px;

    ${(props) =>
      css`
        background-image: url(${props.mobileImage});
      `}
  }
`;

const IndicatorContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 30px;
  display: flex;
`;

const IndicatorFirst = styled.img`
  height: 20px;
`;

const IndicatorArrow = styled.img``;

const IndicatorCurrent = styled.span`
  font-size: 13px;
  color: white;
  font-family: 'NanumSquareRoundEB';
  line-height: 20px;
`;

const ContentContainer = styled.div`
  width: 640px;
  margin: 10px auto 10px;
  text-align: center;
  padding: 34px 40px 0px;
  justify-content: center;

  @media ${theme.desktop} {
    display: block;
    width: inherit;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 25px;
    margin-bottom: 0px;
    border-radius: 10px;
    padding: 24px 10px;
  }
`;

const ItemContainer = styled.div`
  margin-bottom: 20px;

  @media ${theme.desktop} {
  }
`;

const Input = styled.input`
  width: 100%;
  color: #adb2bb;
  border: 1px solid #d8dadd;
  border-radius: 30px;
  height: 56px;
  padding-left: 18px;
  padding-right: 18px;
  margin-top: 12px;
  font-family: 'NanumSquareRoundR';
`;

const ActionButtonLogin = styled.button`
  background-color: #20c673;
  width: 100%;
  height: 56px;
  color: white;
  font-size: 16px;
  font-family: 'NanumSquareRoundEB';
  border-radius: 30px;
  margin-top: 40px;

  @media ${theme.desktop} {
    margin-top: 28px;
  }
`;

const LoginContainer = styled.div`
  font-family: 'NanumSquareRoundR';
  color: #383b40;
  font-size: 14px;
  text-align: center;
  margin-bottom: 100px;
`;
const LoginTxt = styled.span`
  color: #20c673;
  font-family: 'NanumSquareRoundEB';
  cursor: pointer;
`;

export default Login;

import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';

const SitterCard = ({ name, region, nationality, age, hourlyWage, tag }) => {
  const priceToString = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    <SitterCardWrap>
      <Information>
        <ProfileImg></ProfileImg>
        <CardInfo>
          <Name>{name}</Name>
          <Region>
            지역<span>|</span>
            {region}
          </Region>
          <Nationality>
            국적<span>|</span>
            {nationality}
          </Nationality>
          <Others>
            {age}세<span>|</span>희망시급 {priceToString(hourlyWage)}원
          </Others>
          <TagList>
            {tag.map((item) => (
              <Tag>{item}</Tag>
            ))}
          </TagList>
        </CardInfo>
      </Information>
      {/* <Button>시터 신청하기</Button> */}
    </SitterCardWrap>
  );
};

const SitterCardWrap = styled.div`
  width: 960px;
  height: 275px;
  background-color: white;
  border-radius: 30px;
  margin: 0 auto;
  display: flex;
  padding: 0 50px 0 45px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  & + & {
    margin-top: 20px;
  }
`;

const Information = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileImg = styled.div`
  width: 195px;
  height: 195px;
  background-color: #eee;
  border-radius: 50%;
`;

const CardInfo = styled.div`
  margin: 0 0 0 50px;
`;

const Name = styled.p`
  font-size: 20px;
  font-family: 'GmarketSansMedium';
  letter-spacing: -1px;
  margin: 0 0 20px 0;
`;

const Region = styled.p`
  font-size: 16px;
  line-height: 16px;
  margin: 0 0 10px 0;

  & > span {
    margin: 0 10px;
  }
`;

const Nationality = styled(Region)``;

const Others = styled(Region)``;

const TagList = styled.div`
  display: flex;
  margin: 20px 0 0 0;
`;

const Tag = styled.div`
  border: 1px solid black;
  font-size: 16px;
  font-family: 'GmarketSansMedium';
  padding: 8px 12px;
  border-radius: 20px;

  & + & {
    margin-left: 8px;
  }
`;

const Button = styled.button`
  border: 4px solid ${theme.colors.primary};
  width: 156px;
  height: 60px;
  border-radius: 16px;
  background-color: white;
  font-size: 20px;
  font-family: 'GmarketSansMedium';
  color: ${theme.colors.primary};
`;

export default SitterCard;

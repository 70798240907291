import axios from 'axios';

export const instance = axios.create({
  baseURL: 'https://api.ko-life.co.kr',
});

instance.interceptors.request.use(
  function (config) {
    config.headers['Content-Type'] = 'application/json; charset=utf-8';
    if (localStorage.getItem('token')) {
      config.headers['X-GLOBALSITTER-AUTH-TOKEN'] = localStorage.getItem('token');
    }
    return config;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  function (response) {
    console.log(response);

    return response;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  },
);

export const updateFileApi = (data) => instance.post(`/files`, data);

export const getCountriesApi = () => instance.get(`/countries`);

export const getRegionsApi = (parentId) =>
  instance.get(`/regions?parentId=${parentId ? parentId : ''}&pageSize=40`);

export const postCertificationsApi = (body) => instance.post(`/global-sitter/certifications`, body);

export const postCertificationsCodeApi = (body) =>
  instance.post(`/global-sitter/certifications/code`, body);

export const postEducationRequestsApi = (body) =>
  instance.post(`/global-sitter/education-requests`, body);

export const postFindPasswordApi = (body) =>
  instance.post(`/global-sitter/email-for-find-password`, body);

export const postParentUsersCertificationApi = (body) =>
  instance.post(`/global-sitter/parent-users/certifications`, body);

export const postParentUsersCertificationCodeApi = (body) =>
  instance.post(`/global-sitter/parent-users/certifications/code`, body);

export const postParentSitterRequestsApi = (body) =>
  instance.post(`/global-sitter/parent-users/sitter-requests`, body);

/* 지금 안 되어있는 api */
export const postSitterInfosApi = (pageNumber, pageSize) =>
  instance.post(`/global-sitter/sitter-infos?pageNumber=${pageNumber}&pageSize=${pageSize}`);

export const postSitterUsersApi = (body) => instance.post(`/global-sitter/sitter-users`, body);

export const postSitterUsersAuthApi = (body) =>
  instance.post(`/global-sitter/sitter-users/authentication`, body);

export const getSitterUsersMe = () => instance.get(`/global-sitter/sitter-users/me`);

/* 화면 없음 */
export const postPasswordModification = (body) =>
  instance.post(`/global-sitter/sitter-users/passwords/modification`, body);

export const modifySitterInfos = (body) =>
  instance.post(`/global-sitter/sitter-users/sitter-infos`, body);

import {
  postCertificationsApi,
  postCertificationsCodeApi,
  postEducationRequestsApi,
  updateFileApi,
} from 'api/API';
import { makeAutoObservable, runInAction } from 'mobx';

class CommonStore {
  cellphoneShowInput = false;
  checkCertification = false;

  constructor() {
    makeAutoObservable(this);
  }

  setCellphoneShowInput = (param) => {
    this.cellphoneShowInput = param;
    console.log(param);
  };

  setCheckCertification = (param) => {
    this.checkCertification = param;
    console.log(param);
  };

  postEducationRequests = async (data) => {
    await postEducationRequestsApi(data)
      .then((res) => {
        console.log(res);
        if ('SUCCESS' !== res.data.responseCode) {
          alert('요청에 실패했습니다.');
        } else {
          alert('요청에 성공했습니다.');
          window.location.href = '/';
        }
      })
      .catch((error) => {
        console.log(error);
        alert('요청에 실패했습니다.');
      });
  };

  postCertificationCode = async (data) => {
    this.setCheckCertification(false);
    await postCertificationsCodeApi(data)
      .then((res) => {
        console.log(res);
        this.setCellphoneShowInput(true);
        if ('SUCCESS' !== res.data.responseCode) {
          alert('요청에 실패했습니다.');
        }
      })
      .catch((error) => {
        console.log(error);
        alert('요청에 실패했습니다.');
      });
  };

  postCertification = async (data) => {
    await postCertificationsApi(data)
      .then((res) => {
        console.log(res);
        if (res.data.responseCode === 'SUCCESS') {
          this.setCheckCertification(true);
        } else {
          alert('요청에 실패했습니다.');
        }
      })
      .catch((error) => {
        console.log(error);
        alert('요청에 실패했습니다.');
      });
  };

  updateFile = async (data) => {
    return updateFileApi(data);
  };
}

const commonStore = new CommonStore();

export default commonStore;

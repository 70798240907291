import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';

import logoWhite from 'resources/img/logo-white.png';
import IcnFb from 'resources/img/icn_fb.png';
import IcnTwitter from 'resources/img/icn_twitter.png';
import IcnInsta from 'resources/img/icn_insta.png';
import IcnYoutube from 'resources/img/icn_youtube.png';

const Footer = () => {
  const handleClickFb = () => {};

  const handleClickTw = () => {};

  const handleClickIg = () => {};

  const handleClickYt = () => {};
  return (
    <Wrap>
      <Footerbox>
        <FooterLeft>
          <Logo>
            <img src={logoWhite} alt="logo-white" />
          </Logo>
          <Information>
            아웃랜더 OUTLANDER&nbsp;&nbsp;&nbsp;&nbsp;대표 : 윤미혜&nbsp;&nbsp;&nbsp;&nbsp;메일 :
            outlander@naver.com
            <br />
            전화 : 043-851-0513&nbsp;&nbsp;&nbsp;&nbsp;사업자등록번호 : 492-87-02358
            <br />
            주소 : 충북 충주시 충원대로 268, 창업보육센터 407호
            <br />
            <br />
            Copyright Globalsitter © 2023. All rights reserved.
            <br />
          </Information>
        </FooterLeft>
        <IcnContainer>
          <IcnImg onClick={handleClickFb} src={IcnFb} />
          <IcnImg onClick={handleClickTw} src={IcnTwitter} />
          <IcnImg onClick={handleClickIg} src={IcnInsta} />
          <IcnImg onClick={handleClickYt} src={IcnYoutube} />
        </IcnContainer>
      </Footerbox>
    </Wrap>
  );
};

const Wrap = styled.div`
  background-color: ${theme.colors.dark};
  display: flex;
  justify-content: center;
  padding: 52px 0;

  @media ${theme.desktop} {
    display: block;
    padding: 26px 20px;
  }
`;

const Footerbox = styled.div`
  width: 1280px;
  max-width: 1280px;
  display: flex;
  justify-content: space-between;

  @media ${theme.desktop} {
    width: 100%;
    display: block;
  }
`;

const FooterLeft = styled.div`
  display: flex;

  @media ${theme.desktop} {
    display: block;
  }
`;

const Logo = styled.div`
  margin: 0 86.4px 0 0;

  @media ${theme.desktop} {
  }
`;

const Information = styled.p`
  font-family: 'Pretendard';
  color: ${theme.colors.light};
  font-size: 13px;
  line-height: 25px;
  letter-spacing: -0.5px;
  margin: 0;

  @media ${theme.desktop} {
    margin-top: 20px;
  }
`;

const IcnImg = styled.img`
  margin-left: 15px;
  cursor: pointer;
`;

const IcnContainer = styled.div`
  @media ${theme.desktop} {
    margin-top: 20px;
  }
`;

export default Footer;

import { getRegionsApi } from 'api/API';
import { makeAutoObservable } from 'mobx';

class RegionStore {
  regionData = [];
  regionSecondData = [];
  regionThirdData = [];

  constructor() {
    makeAutoObservable(this);
  }

  setRegionData = (param) => {
    this.regionData = param;
  };

  setRegionSecondData = (param) => {
    this.regionSecondData = param;
  };

  setRegionThirdData = (param) => {
    this.regionThirdData = param;
  };

  getRegions = async () => {
    await getRegionsApi()
      .then((res) => {
        this.setRegionData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getSecondRegions = async (parentId) => {
    await getRegionsApi(parentId)
      .then((res) => {
        this.setRegionSecondData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getThirdRegions = async (parentId) => {
    await getRegionsApi(parentId)
      .then((res) => {
        this.setRegionThirdData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

const regionStore = new RegionStore();

export default regionStore;

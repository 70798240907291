import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import Navbar from 'components/templates/Navbar';
import Footer from 'components/templates/Footer';
import DaumPostcode from 'react-daum-postcode';
import LabeledInput from 'components/atoms/LabeledInput';
import PopUp from 'components/atoms/PopUp';
import { observer } from 'mobx-react';
import stores from 'stores';
import { toJS } from 'mobx';

const SignEducation = observer(() => {
  const { commonStore, counrtyStore } = stores;

  useEffect(() => {
    counrtyStore.getCountries();
  }, [counrtyStore]);

  const countryStoreData = toJS(counrtyStore.countryData);

  const [inputs, setInputs] = useState({
    name: '',
    nationality: '',
    birth: '',
    contact: '',
    email: '',
    address: '',
    addressCode: '',
    sido: '',
    sigungu: '',
    sigunguCode: '',
    detailAddress: '',
    certificationCode: '',
  });

  const {
    name,
    nationality,
    birth,
    contact,
    email,
    address,
    addressCode,
    sido,
    sigungu,
    sigunguCode,
    detailAddress,
    certificationCode,
  } = inputs;

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const onBirthChange = (e) => {
    const { value, name } = e.target;
    // console.log(value, name);
    let birthFormatted = '';
    let onlyNumber = value.replaceAll('-', '');
    if (onlyNumber.length > 6) {
      birthFormatted = `${onlyNumber.substring(0, 4)}-${onlyNumber.substring(
        4,
        6,
      )}-${onlyNumber.substring(6, 8)}`;
    } else if (onlyNumber.length > 4) {
      birthFormatted = `${onlyNumber.substring(0, 4)}-${onlyNumber.substring(4, 6)}`;
    } else {
      birthFormatted = onlyNumber.substring(0, 4);
    }
    setInputs({
      ...inputs,
      [name]: birthFormatted,
    });
  };

  const [showPostCode, setShowPostCode] = useState(false);

  const handleAddressComplete = (data) => {
    setInputs({
      ...inputs,
      address: data.address,
      addressCode: data.zonecode,
      sido: data.sido,
      sigungu: data.sigungu,
      sigunguCode: data.sigunguCode,
    });
    setShowPostCode(false);
  };

  const postCodeStyle = {
    display: 'block',
    position: 'relative',
    top: '17px',
    left: '0',
    width: '700px',
    height: '400px',
    zIndex: 100,
    border: '2px solid #bcbcbc',
    borderRadius: '10px',
    padding: '5px',
  };

  const [isChecked, setIsChecked] = useState(false);

  const [showCertification, setShowCertification] = useState(false);

  const handleCertification = () => {
    commonStore.postCertificationCode({ cellphone: contact });
  };

  useEffect(() => {
    if (commonStore.cellphoneShowInput === true) {
      setShowCertification(true);
    }
  }, [commonStore.cellphoneShowInput]);

  const handleCheckCertificationCode = () => {
    commonStore.postCertification({ cellphone: contact, code: certificationCode });
  };

  const [checkCertification, setCheckCertification] = useState(false);

  useEffect(() => {
    if (commonStore.checkCertification === true) {
      setCheckCertification(true);
    }
  }, [commonStore.checkCertification]);

  const [popupVisible, setPopupVisible] = useState(false);

  const handleOpenPopup = () => {
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  const [countryInfo, setCountryInfo] = useState(null);

  const handleCountryTab = (tab) => {
    setCountryInfo(tab);
  };

  const handleSubmit = () => {
    if (!addressCode || !birth || !contact || !email || !countryInfo) {
      alert('필수 값을 입력해 주세요.');
      return;
    }
    if (!checkCertification) {
      alert('휴대폰 인증이 완료되지 않았습니다.');
      return;
    }
    if (!isChecked) {
      alert('약관에 동의해 주세요.');
      return;
    }
    const data = {
      address: address,
      addressCode: addressCode,
      addressDetails: detailAddress,
      sido: sido,
      sigungu: sigungu,
      sigunguCode: sigunguCode,
      birthDate: birth,
      cellphone: contact,
      country: countryInfo,
      name: name,
      email: email,
    };
    console.log(data);
    commonStore.postEducationRequests(data);
  };

  return (
    <>
      <Navbar />
      <Wrap>
        <Title>교육 신청하기</Title>
        <Description>
          최종 교육 신청과 교재 배송을 도와드릴 수 있도록,
          <br />
          아래 내용을 작성하신 후 '제출하기' 버튼을 눌러주세요.
        </Description>
        <Form>
          <LabeledInput
            label="이름"
            name="name"
            value={name}
            placeholder="이름을 입력해주세요"
            onChange={onChange}
          />
          <LabelInput style={{ marginTop: 75 }}>
            <Label>연락처</Label>
            <ContactInputWrap>
              <Input
                name="contact"
                value={contact}
                placeholder="연락처를 입력해주세요"
                onChange={onChange}
                disabled={checkCertification}
              />
              <ContactInputButton
                type="button"
                onClick={() => handleCertification()}
                disabled={checkCertification}
              >
                번호 인증
              </ContactInputButton>
            </ContactInputWrap>
            {showCertification && (
              <ContactInputWrap>
                <Input
                  name="certificationCode"
                  value={certificationCode}
                  placeholder="인증번호를 입력해주세요"
                  onChange={onChange}
                  disabled={checkCertification}
                />
                <ContactInputButton
                  type="button"
                  onClick={() => handleCheckCertificationCode()}
                  disabled={checkCertification}
                >
                  인증번호 확인
                </ContactInputButton>
              </ContactInputWrap>
            )}
          </LabelInput>
          <LabeledInput
            label="이메일"
            name="email"
            value={email}
            placeholder="이메일을 입력해주세요"
            onChange={onChange}
          />
          <LabelInput style={{ marginTop: 75 }}>
            <Label>국적</Label>
            <NationalButton onClick={handleOpenPopup} type="button">
              {countryInfo ? countryInfo.nameInOrigin : '국적을 입력해주세요'}
            </NationalButton>
          </LabelInput>
          <LabeledInput
            label="생년월일"
            name="birth"
            value={birth}
            placeholder="생년월일을 입력해주세요 (ex. 2000-01-01)"
            onChange={onBirthChange}
          />
          <LabelInput style={{ marginTop: 75 }}>
            <Label>주소</Label>
            <ZipCode>
              <ZipCodeInput
                name="addressCode"
                placeholder="우편번호"
                defaultValue={inputs.addressCode}
                disabled
              />
              <ZipCodeButton type="button" onClick={() => setShowPostCode(!showPostCode)}>
                우편번호
              </ZipCodeButton>
            </ZipCode>
            <AddressInput placeholder="주소" defaultValue={inputs.address} disabled />
            <AddressInput
              name="detailAddress"
              value={detailAddress}
              placeholder="상세주소를 입력해주세요"
              onChange={onChange}
            />
            {showPostCode ? (
              <div>
                <DaumPostcode onComplete={handleAddressComplete} style={postCodeStyle} />
              </div>
            ) : (
              <></>
            )}
          </LabelInput>
          <PrivacyNotice>
            &lt;개인정보 처리 방침&gt; 수강생 출석 확인과 서비스 품질 개선 목적으로 ZOOM 강의 영상을
            실시간녹화하고 있습니다. 해당 녹화본은 수집 목적 외 용도로 사용되지 않으며, 보유기간이
            지난 후 즉시 폐기됩니다.
          </PrivacyNotice>
          <CheckboxLabel>
            <Checkbox
              type="checkbox"
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
            />
            <CheckboxText>개인정보 수집/이용 동의</CheckboxText>
          </CheckboxLabel>
          <SubmitButton type="button" onClick={() => handleSubmit()}>
            신청하기
          </SubmitButton>
        </Form>
      </Wrap>
      <Footer />
      <PopUp visible={popupVisible} onClose={handleClosePopup}>
        <PopUpTitle>국적 선택</PopUpTitle>
        <PopUpTab>
          {countryStoreData.map((item) => (
            <PopUpLanguageItemWrap
              onClick={() => handleCountryTab(item)}
              tab={countryInfo?.countryIsoCode === item.countryIsoCode}
              key={item.countryIsoCode}
            >
              <PopUpLanguageItem>
                <Flag>
                  <img src={item.imageUrl} alt="flag" />
                </Flag>
                <FlagText>{item.nameInOrigin}</FlagText>
              </PopUpLanguageItem>
            </PopUpLanguageItemWrap>
          ))}
        </PopUpTab>
        <PopUpButton onClick={handleClosePopup}>선택완료</PopUpButton>
      </PopUp>
    </>
  );
});
const Wrap = styled.div`
  padding: 106px 0 0 0;

  @media ${theme.laptop} {
    padding: 60px 0 0 0;
  }
`;
const Title = styled.p`
  color: ${theme.colors.deepGreen};
  font-family: 'GmarketSansMedium';
  font-size: 50px;
  letter-spacing: -1.25px;
  text-align: center;
  margin: 72px 0 53px 0;
`;

const Description = styled.p`
  font-size: 25px;
  letter-spacing: -1.25px;
  text-align: center;
  margin: 0;
  color: ${theme.colors.deepGreen};
  font-family: 'GmarketSansMedium';
`;

const Form = styled.form`
  width: 700px;
  margin: 80px auto 0;
`;

const LabelInput = styled.div`
  margin: 0 0 100px 0;
  position: relative;
`;

const Label = styled.p`
  font-size: 30px;
  line-height: 30px;
  font-family: 'GmarketSansMedium';
  letter-spacing: -1.5px;
  color: #2d2d2d;
  margin: 0 0 17px 7px;
`;

const Input = styled.input`
  border: 2px solid ${theme.colors.gray};
  width: 100%;
  height: 83px;
  border-radius: 10px;
  font-size: 25px;
  font-family: 'GmarketSansMedium';
  padding: 0 26px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${theme.colors.gray};
  }

  &:disabled {
    background-color: #f4f4f4;
    color: #bcbcbc;
  }
`;

const ContactInputWrap = styled.div`
  display: flex;

  & + & {
    margin-top: 10px;
  }
`;

const ZipCode = styled.div`
  display: flex;
`;

const ZipCodeInput = styled(Input)``;

const ZipCodeButton = styled.button`
  height: 83px;
  border: 2px solid ${theme.colors.gray};
  border-radius: 10px;
  font-size: 21px;
  font-family: 'GmarketSansMedium';
  color: #848484;
  background-color: white;
  width: 200px;
  margin: 0 0 0 15px;
`;

const ContactInputButton = styled(ZipCodeButton)`
  &:disabled {
    cursor: not-allowed;
    background-color: #f4f4f4;
  }
`;

const AddressInput = styled(Input)`
  margin: 17px 0 0 0;
`;

const PrivacyNotice = styled.p`
  font-family: 'GmarketSansLight';
  font-size: 22px;
  line-height: 46px;
  letter-spacing: -1.1px;
  color: #353535;
`;

const CheckboxLabel = styled.label`
  font-size: 30px;
  letter-spacing: -1.5px;
  font-family: 'GmarketSansMedium';
  margin: 0;
  line-height: 39px;
  display: flex;
`;

const Checkbox = styled.input`
  border: 4px solid #cecece;
  border-radius: 30px;
  width: 39px;
  height: 39px;
  margin: 0 20px 0 0;

  &:checked {
    border: 4px solid ${theme.colors.primary};
    background-color: ${theme.colors.primary};
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
  }
`;

const CheckboxText = styled.div`
  margin: 2px 0 0 0;
`;

const SubmitButton = styled.button`
  border: 4px solid ${theme.colors.primary};
  width: 200px;
  height: 84px;
  border-radius: 50px;
  background-color: white;
  margin: 115px auto 93px;
  font-size: 30px;
  color: ${theme.colors.primary};
  letter-spacing: -0.75px;
  font-family: 'GmarketSansMedium';
  display: block;
`;

const PopUpTitle = styled.p`
  text-align: center;
  font-family: 'GmarketSansMedium';
  font-size: 20px;
`;

const PopUpTab = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 40px 0 0 0;
`;

const PopUpLanguageItemWrap = styled.div`
  width: 92px;
  height: 108px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.tab === true &&
    css`
      background-color: rgba(7, 0, 133, 0.2);
      border-radius: 5px;
    `}
`;

const PopUpLanguageItem = styled.div`
  text-align: center;
`;

const Flag = styled.div`
  width: 30px;
  height: 20px;
  background-color: #eee;
  margin: 0 auto 25px;

  & > img {
    width: 30px;
    height: 20px;
    object-fit: cover;
  }
`;

const FlagText = styled.p`
  font-size: 12px;
  font-family: 'GmarketSansMedium';
`;

const PopUpButton = styled.button`
  width: 150px;
  border-radius: 30px;
  height: 60px;
  border: 2px solid ${theme.colors.primary};
  margin: 20px auto;
  color: ${theme.colors.primary};
  background-color: white;
  font-size: 20px;
  display: block;
  font-family: 'GmarketSansMedium';
`;

const NationalButton = styled.button`
  border: 2px solid ${theme.colors.gray};
  width: 100%;
  height: 83px;
  border-radius: 10px;
  font-size: 25px;
  font-family: 'GmarketSansMedium';
  padding: 23px 26px;
  color: ${theme.colors.gray};
  margin: 0 0 15px 0;
  background-color: transparent;
  text-align: left;
`;

export default SignEducation;

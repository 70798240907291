const media = {
  mobileS: '320px',
  mobile: '576px',
  tablet: '820px',
  laptop: '1047px',
  desktop: '1440px',
};

const theme = {
  mobileS: `(max-width: ${media.mobileS})`,
  mobile: `(max-width: ${media.mobile})`,
  tablet: `(max-width: ${media.tablet})`,
  laptop: `(max-width: ${media.laptop})`,
  desktop: `(max-width: ${media.desktop})`,
  colors: {
    primary: '#20c673',
    deepGreen: '#254935',
    lightGrayGreen: '#939e97',
    grayGreen: '#426650',
    backgroundGray: '#f4f4f4',
    gray: '#bcbcbc',
    dark: '#383B40',
    light: '#ADB2BB',
  },
};

export default theme;

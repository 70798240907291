import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import Navbar from 'components/templates/Navbar';
import Footer from 'components/templates/Footer';
import SitterCard from './SitterCard';
import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import stores from 'stores';
import { observe, toJS } from 'mobx';

import searchIcon from 'resources/img/search-icon.png';

const data = [
  {
    profileImg: '',
    name: 'Phu Myat Thwe',
    region: '서울특별시 노원구 강남구 송파구',
    nationality: '베트남',
    age: '28',
    hourlyWage: '12000',
    tag: ['기초 영어 교육', '베트남어 교육', '4~10세 교육'],
  },
  {
    profileImg: '',
    name: 'Phu Myat Thwe',
    region: '서울특별시 노원구 강남구 송파구',
    nationality: '베트남',
    age: '28',
    hourlyWage: '12000',
    tag: ['기초 영어 교육', '베트남어 교육', '4~10세 교육'],
  },
  {
    profileImg: '',
    name: 'Phu Myat Thwe',
    region: '서울특별시 노원구 강남구 송파구',
    nationality: '중국',
    age: '28',
    hourlyWage: '12000',
    tag: ['중국어 교육', '7~12세 교육'],
  },
  {
    profileImg: '',
    name: 'Phu Myat Thwe',
    region: '서울특별시 노원구 강남구 송파구',
    nationality: '필리핀',
    age: '28',
    hourlyWage: '12000',
    tag: ['기초 영어 교육', '4 ~ 10세 교육'],
  },
];

const SitterSearching = observer(() => {
  const { sitterStore } = stores;

  const [active, setActive] = useState('region');
  const navigate = useNavigate();

  const handleTab = (tab) => {
    setActive(tab);
  };

  const requestSitter = () => {
    if (!!window.localStorage.getItem('token')) {
      navigate('/applicationSitter');
    } else {
      navigate('/login');
    }
  };

  const registerSitter = () => {
    navigate('/signSitter', {});
  };

  return (
    <>
      <Navbar active={'sitterSearching'} />
      <Wrap>
        <TitleSection>
          <Title>시터 찾기</Title>
        </TitleSection>
        <TabSection>
          <Tab active={true} onClick={() => requestSitter()}>
            신청하기
          </Tab>
          <Tab active={true} onClick={() => registerSitter()}>
            등록하기
          </Tab>
        </TabSection>
        <SitterListWrap>
          {data.map((item) => (
            <SitterCard
              name={item.name}
              region={item.region}
              nationality={item.nationality}
              age={item.age}
              hourlyWage={item.hourlyWage}
              tag={item.tag}
            />
          ))}
        </SitterListWrap>
      </Wrap>
      <Footer />
    </>
  );
});

const Wrap = styled.div`
  padding: 106px 0 0 0;

  @media ${theme.laptop} {
    padding: 60px 0 0 0;
  }
`;

const TitleSection = styled.div`
  display: flex;
  justify-content: center;
  width: 960px;
  margin: 62px auto 85px;
`;

const Title = styled.p`
  font-size: 50px;
  color: ${theme.colors.deepGreen};
  letter-spacing: -1.25px;
  font-family: 'GmarketSansMedium';
  margin: 0;
`;

const TabSection = styled.div`
  width: 575px;
  margin: 0 auto 20px;
`;

const Tab = styled.button`
  border: 2px solid #b2b1ad;
  width: 240px;
  height: 65px;
  border-radius: 40px;
  background-color: white;
  font-size: 24px;
  font-family: 'GmarketSansMedium';

  ${(props) =>
    props.active === true &&
    css`
      border: 4px solid ${theme.colors.primary};
    `}

  & + & {
    margin-left: 95px;
  }
`;

const SitterListWrap = styled.div`
  background-color: ${theme.colors.backgroundGray};
  padding: 45px 0;
`;

export default SitterSearching;

import Navbar from 'components/templates/Navbar';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import bgMenuCommon from 'resources/img/bg_menu_common.png';
import bgMenuCommonMobile from 'resources/img/bg_menu_common_mobile.png';
import bgIntroduce from 'resources/img/bg_introduce.png';
import bgIntroduceMobile from 'resources/img/bg_introduce_mobile.png';
import logoGreen from 'resources/img/logo_green.png';
import icnHome from 'resources/img/icn_home.png';
import icnArrowRight from 'resources/img/icn_arrow_right.png';
import Footer from 'components/templates/Footer';

const Introduce = () => {
  return (
    <>
      <Navbar active={'introduce'} />
      <Wrap>
        <CurrentMenu image={bgMenuCommon} mobileImage={bgMenuCommonMobile}>
          <IndicatorContainer>
            <IndicatorFirst src={icnHome} />
            <IndicatorArrow src={icnArrowRight} />
            <IndicatorCurrent>회사소개</IndicatorCurrent>
          </IndicatorContainer>
          회사소개
        </CurrentMenu>
        <ContentContainer image={bgIntroduce} mobileImage={bgIntroduceMobile}>
          <Logo src={logoGreen} />
          <TxtType1>
            글로벌시터는 새로운 육아문화 정착을 위하여
            <br />
            전문화된 베이비시터 서비스를 제공하는 기업입니다.{' '}
          </TxtType1>
          <TxtType2>
            글로벌시터는 사회적경제 기업으로 사회적기업가 육성사업을 시작으로 탄생하게 되었습니다.
            <br />
            2022년 예비 사회적기업 인증을 받았고, 사회적기업의 가치를 기반으로 서비스를 운영하고
            있습니다.
            <br />
            <br />
            한국에 이주에 해온 외국인들 특히 결혼 이주여성들이 본인의 모국어와 한국어에 강점이 있고,{' '}
            <br />
            또한 대부분 아이들을 키워본 경험이 많으며, 한국 거주기간이 타 비자 외국인보다 길어 한국
            정서에 대한 이해도도 높습니다.
            <br />
            <br />
            저희 글로벌시터는 이러한 분들과 함께 처음 아이돌봄 서비스를 시작하였고, <br />
            이중언어 교육도함께 제공하여 이제는 단순 아이 돌보미가 아닌 이중언어 아이돌보미로 새롭게
            시작하게 되었습니다.
            <br />
            글로벌 시터를 통해, 우리 아이의 외국어 능력과 돌봄을 한 번에 해결하세요!
            <br />
          </TxtType2>
          <LastTxt>대표이사 윤미혜 드림</LastTxt>
        </ContentContainer>
      </Wrap>
      <Footer />
    </>
  );
};

const Wrap = styled.div`
  padding: 106px 0 0 0;

  @media ${theme.laptop} {
    padding: 60px 0 0 0;
  }
`;

const CurrentMenu = styled.div`
  height: 320px;
  color: white;
  font-family: 'NPSfontEB';
  font-size: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  ${(props) =>
    css`
      background-image: url(${props.image});
    `}

  @media ${theme.desktop} {
    height: 240px;
    font-size: 36px;

    ${(props) =>
      css`
        background-image: url(${props.mobileImage});
      `}
  }
`;

const IndicatorContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 30px;
  display: flex;
`;

const IndicatorFirst = styled.img`
  height: 20px;
`;

const IndicatorArrow = styled.img``;

const IndicatorCurrent = styled.span`
  font-size: 13px;
  color: white;
  font-family: 'NanumSquareRoundEB';
  line-height: 20px;
`;

const ContentContainer = styled.div`
  width: 1080px;
  margin: 50px auto 120px;
  text-align: center;
  padding: 84px 40px;
  ${(props) =>
    css`
      background-image: url(${props.image});
    `}

  @media ${theme.desktop} {
    width: inherit;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 25px;
    margin-bottom: 80px;
    border-radius: 10px;
    padding: 34px 10px;
    background-repeat: no-repeat;
    background-size: cover;

    ${(props) =>
      css`
        background-image: url(${props.mobileImage});
      `}
  }
`;

const Logo = styled.img`
  width: 250px;

  @media ${theme.desktop} {
    width: 140px;
  }
`;

const TxtType1 = styled.div`
  font-family: 'NPSfontEB';
  color: #383b40;
  font-size: 28px;
  margin-top: 20px;

  @media ${theme.desktop} {
    font-size: 18px;
  }
`;

const TxtType2 = styled.div`
  font-size: 14px;
  line-height: 150%;
  color: #383b40;
  margin-top: 18px;
  font-family: 'NanumSquareRoundR';

  @media ${theme.desktop} {
    font-size: 14px;
  }
`;

const LastTxt = styled.div`
  font-size: 14px;
  color: #383b40;
  margin-top: 20px;
  font-family: 'NanumSquareRoundEB';
`;

export default Introduce;

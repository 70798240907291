import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import Navbar from 'components/templates/Navbar';
import eduImg from 'resources/img/edu-img.jpeg';
import CountdownTimer from './CountdownTimer';
import { Link } from 'react-router-dom';

const OnlineEducation = () => {
  const DueDate = 10 * 24 * 60 * 60 * 1000;
  const NowDate = new Date().getTime();

  const dateTimeAfterThreeDays = NowDate + DueDate;
  return (
    <>
      <Navbar />
      <Wrap>
        <Img>
          <img src={eduImg} alt="edu-img" />
        </Img>
        <BottomWrap>
          <LeftWrap>
            <LeftText>
              <span>온라인 교육 모집 마감</span>
              <br />
              <CountdownTimer targetDate={dateTimeAfterThreeDays} />
            </LeftText>
          </LeftWrap>
          <RightWrap>
            <Discount>
              <span>50%</span> 할인중
            </Discount>
            <Link to="/signEdu">
              <Button>신청하기</Button>
            </Link>
          </RightWrap>
        </BottomWrap>
      </Wrap>
    </>
  );
};

const Wrap = styled.div`
  padding: 106px 0 0 0;

  @media ${theme.laptop} {
    padding: 60px 0 0 0;
  }
`;

const Img = styled.div`
  width: 970px;
  margin: 0 auto;

  & > img {
    width: 100%;
  }
`;

const BottomWrap = styled.div`
  box-shadow: 0 2px 5px 0 rgba(183, 183, 183, 0.75);
  height: 170px;
  display: flex;
  padding: 0 73px 0 134px;
  align-items: center;
  justify-content: space-between;
`;

const LeftWrap = styled.div``;

const LeftText = styled.p`
  font-size: 33px;
  color: ${theme.colors.primary};
  font-family: 'GmarketSansBold';
  text-align: center;

  & > span {
    font-size: 30px;
    letter-spacing: -0.75px;
    font-family: 'GmarketSansMedium';
    color: black;
  }
`;

const RightWrap = styled.div`
  position: relative;
`;

const Discount = styled.div`
  width: 174px;
  height: 55px;
  padding: 10px 0 0 0;
  border: 2px solid ${theme.colors.primary};
  background-color: white;
  font-size: 25px;
  font-family: 'GmarketSansMedium';
  text-align: center;
  border-radius: 30px;
  position: absolute;
  top: -90px;
  left: 73px;

  & > span {
    font-family: 'GmarketSansBold';
  }

  &:before {
    border-top: 20px solid #ffffff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 0px solid transparent;
    content: '';
    position: absolute;
    top: 51px;
    left: 76px;
    z-index: 1;
  }
  &:after {
    border-top: 24px solid ${theme.colors.primary};
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 0px solid transparent;
    content: '';
    position: absolute;
    top: 51px;
    left: 74px;
  }
`;

const Button = styled.button`
  width: 320px;
  height: 89px;
  border-radius: 50px;
  background-color: ${theme.colors.primary};
  color: white;
  font-size: 33px;
  font-family: 'GmarketSansBold';
  letter-spacing: -0.82px;
  box-shadow: 0 2px 5px 0 rgba(183, 183, 183, 0.75);
`;

export default OnlineEducation;

import React, { useEffect, useState } from 'react';
import Navbar from 'components/templates/Navbar';
import Footer from 'components/templates/Footer';
import SignSitterBasic from './SignSitterBasic';
import SignSitterAdd from './SignSitterAdd';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import stores from 'stores';

const SignSitter = observer(() => {
  const location = useLocation();
  const { sitterStore } = stores;

  console.log(location);

  const tab = sitterStore.tab;

  return (
    <>
      <Navbar />
      {tab === 'basic' ? (
        <SignSitterBasic modify={location?.state?.modify} />
      ) : (
        <SignSitterAdd modify={location?.state?.modify} />
      )}
      <Footer />
    </>
  );
});

export default SignSitter;

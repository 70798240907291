import sitterStore from './SitterStore';
import counrtyStore from './CountryStore';
import regionStore from './RegionStore';
import commonStore from './CommonStore';
import parentStore from './ParentStore';
import authStore from './AuthStore';

const stores = {
  sitterStore,
  counrtyStore,
  regionStore,
  commonStore,
  parentStore,
  authStore,
};

export default stores;
